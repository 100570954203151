import React, { useEffect } from "react";
import "../styles/Various.css";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  useEffect(() => {
    document.title = "404 - Not Found";
  });
  return (
    <div className="NotFound">
      <Helmet>
        <meta
          name="description"
          content="Ci dispiace, questa pagina non esiste, prova a navigare verso altre pagine dalla navigation bar"
        />
      </Helmet>
      <h2>
        Ci dispiace, <span className="text-danger">questa pagina non esiste</span>, prova a navigare verso altre pagine dalla
        navigation bar.
      </h2>
      <div className="pt-5">
        <img className="img-fluid rounded" src={require("../img/error/Mare1.jpg").default} alt="Page not found" />
      </div>
    </div>
  );
};

export default NotFound;
