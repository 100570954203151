import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_HOVER_CDN } from "../../utils/constants";

const RilieviGeoDrone = () => {
  useEffect(() => {
    document.title = "Rilievi geo-drone";
  });
  return (
    <div className="RilieviGeoDrone">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta
          name="description"
          content="Rilievi: topografici, catastali con tecniche avanzate laser scanner, del territorio con drone tramite tecnica fotogrammetrica, termografici per lo studio delle acque, di terre e rocce e dei rifiuti. Produzione: ortofoto (mappatura territoriale) con sovrapposizione isoipse, modelli digitali delle altezze (DTM o DSM), modelli 3D. Videoispezioni, disegno e progettazione CAD 2D/3D"
        />
      </Helmet>
      <h1 className="pb-4">Rilievi geologici e con drone</h1>
      <section>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <h4>Rilievo del territorio</h4>
            <ul className="px-4 py-2">
              <li>Rilievi topografici, catastali con tecniche avanzate laser scanner</li>
              <li>Rilievi del territorio con drone tramite tecnica fotogrammetrica</li>
              <li>Rilievi termografici per lo studio delle acque, di terre e rocce e dei rifiuti</li>
              <li>Produzione ortofoto (mappatura territoriale) con sovrapposizione isoipse</li>
              <li>Produzione modelli digitali delle altezze (DTM o DSM)</li>
              <li>Produzione modelli 3D</li>
              <li>Videoispezioni</li>
              <li>Disegno e progettazione CAD 2D/3D</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/geologia/Rilievo_territorio1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/geologia/Rilievo_territorio2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row flex-md-row-reverse pt-5 pb-5">
          <div className="col-md-6 align-self-center pl-md-5">
            <h4>Strumentazione in dotazione</h4>
            <ul className="px-4 py-2">
              <li>Laser Scanner</li>
              <li>Drone multirotore con sensore ottico fotogrammetrico</li>
              <li>Stazione totale</li>
              <li>GPS topografico</li>
              <li>Sonda multiparametrica per il rilievo speditivo delle acque</li>
              <li>Software per elaborazione nuvole di punti e gestione dati spaziali 3D</li>
              <li>Software per la modellazione idrogeologica e geotecnica/geomeccanica 2D e 3D</li>
              <li>Software di disegno CAD</li>
              <li>
                Software GIS per la gestione dei
                <span className="s1"> dati cartografici</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/geologia/Strumentazione_dotazione1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/geologia/Strumentazione_dotazione2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <h1 className="pb-4">Aerofotogrammetria</h1>
      <section>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <h4>Prodotti 2D</h4>
            <ul className="px-4 py-2">
              <li>
                ortomosaico (immagine zenitale ortorettificata scalata e georeferenziata, perfettamente misurabile con elevata
                accuratezza, fino a 1 cm)
              </li>
              <li>
                cartografia topografica di dettaglio, sovrapponibile all'ortomosaico (curve di livello con equidistanza &gt; 10
                cm)
              </li>
              <li>
                curve di livello in formato CAD e GIS (dxf/dwg/shp), sia 2D che 3D adatte all'inserimento in software di
                modellazione per creazione automatica delle superfici
              </li>
              <li>
                DEM modello digitale dell'elevazione a dettaglio centimetrico (modelli digitali di superficie e modelli digitali
                del terreno)
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/geologia/Prodotti2D_1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right border"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/geologia/Prodotti2D_2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row flex-md-row-reverse pt-5">
          <div className="col-md-6 pl-md-5 align-self-center">
            <h4>Prodotti 3D</h4>
            <ul className="px-4 py-2">
              <li>modello tridimensionale del terreno e delle strutture</li>
              <li>
                nuvola di punti in formato visualizzabile e gestibile con software free/open source (nuvole di punti dense con
                dettaglio centimetrico misurabili nelle tre dimensioni)
              </li>
              <li>estrazione automatica di profili topografici e sezioni in formato CAD</li>
              <li>
                calcolo di volumi di sterro e riporto (attività estrattive all'aperto, monitoraggio fenomeni gravitativi e
                modellamento geomorfologico fluviale e costiero)
              </li>
              <li>
                rilevamento geologico meccanico (estrazione automatica delle famiglie di discontinuità caratterizzanti l'ammasso
                roccioso ed analisi di densità)
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/geologia/Prodotti3D_1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/geologia/Prodotti3D_2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row pt-5">
          <div className="col-md-6 align-self-center">
            <h4>Videoriprese e fotografie aeree</h4>
            <ul className="px-4 py-2">
              <li>ispezione di aree di difficile accesso (aree di frana, aree contaminate, pareti rocciose)</li>
              <li>
                censimento opere di ogni genere (opere di sistemazione idraulico forestale, opere di mitigazione del rischio di
                versante e fluviale, ponti, opere idrauliche)
              </li>
              <li>
                censimento danni conseguenti ad eventi estremi (dissesto idrogeologico in area montana e processi di erosione
                costiera in seguito a mareggiate)
              </li>
              <li>produzione filmati e immagini a fine commerciale pubblicitario e di valorizzazione del paesaggio</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/geologia/Videoriprese_1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/geologia/Videoriprese_2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <p className="pt-4 pb-3">
        <a href="http://www.geovisions.it/" target="_blank" rel="noopener">
          Approfondisci
          <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" />
        </a>
      </p>
    </div>
  );
};

export default RilieviGeoDrone;
