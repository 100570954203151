const IMAGE_HOVER_CDN = (
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/imagehover.css/2.0.0/css/imagehover.min.css"
    integrity="sha512-SYBBAnJsPEzSQ/kBqkR/9krJ+EUgF624c8uaMBww1Q26pnCw5k7zVmtc48BfXjZ9MRNNBveIdhx/na1xRLWgjw=="
    crossorigin="anonymous"
  />
);

export { IMAGE_HOVER_CDN };
