import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import EspImg from "./EspImg";
import seedEsperienze from "../../data/seedEsperienze";
import "../../styles/Various.css";

const Esperienze = ({ esp }) => {
  useEffect(() => {
    document.title = esp.title;
  });
  const findNext = (id) => {
    let i = seedEsperienze.indexOf(id);
    i++;
    i = i % seedEsperienze.length;
    return seedEsperienze[i];
  };
  const findPrev = (id) => {
    let i = seedEsperienze.indexOf(id);
    if (i === 0) i = seedEsperienze.length;
    i--;
    return seedEsperienze[i];
  };

  let next = findNext(esp);
  let prev = findPrev(esp);
  const espImgs = esp.imgs.map((img) => (
    <EspImg link={img.link} path={img.path} name={img.name} key={img.path.split("/").pop().slice(0, -4)} title={esp.title} />
  ));

  return (
    <div className="Esp">
      <Helmet>
        <meta
          name="description"
          content="Esperienze di ingegnieria naturalistica e ambientale, energia, ecoturismo, formazione divise in categorie"
        />
      </Helmet>
      <section className="Esp-title">
        <div className="pb-2">
          <h1>{esp.title}</h1>
          <p className="pt-3">{esp.subtitle}</p>
        </div>
      </section>
      <section className="Esp-img">
        <div className="row">{espImgs}</div>
      </section>
      <section className="Esp-change-page py-4">
        <Link className="float-left btn btn-light" to={`/esperienze/${prev.id}`}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
          {prev.title}
        </Link>
        <Link className="float-right btn btn-light" to={`/esperienze/${next.id}`}>
          {next.title}
          <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </section>
    </div>
  );
};

export default Esperienze;
