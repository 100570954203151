import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../../styles/Various.css";

const LaSocieta = () => {
  useEffect(() => {
    document.title = "La società";
  });
  return (
    <div className="LaSocieta">
      <Helmet>
        <meta
          name="description"
          content="La società IRIS Strategie per l’Ambiente opera da circa 30 anni nei settori della valutazione, tutela ambientale
            e rinaturalizzazione, con particolare focalizzazione all'applicazione delle tecniche di ingegneria naturalistica per la
            difesa del suolo e la riqualificazione paesaggistico-ambientale, diventando un punto di riferimento
            tecnico-scientifico a livello nazionale."
        />
      </Helmet>
      <img
        className="img-fluid rounded panoramic castello"
        src={require("../../img/chi-siamo/la-societa/mattoni.jpg").default}
        alt="La società"
      />
      <section className="la-societa">
        <h1 className="pt-5">La società</h1>
        <p className="pt-4">
          La società <strong>IRIS Strategie per l’Ambiente</strong> opera da circa 30 anni nei settori della valutazione, tutela
          ambientale e rinaturalizzazione, con particolare focalizzazione all'applicazione delle tecniche di{" "}
          <strong>ingegneria naturalistica</strong> per la difesa del suolo e la riqualificazione paesaggistico-ambientale,
          diventando un punto di riferimento tecnico-scientifico a livello nazionale.
        </p>
        <p className="pt-2">
          La IRIS ha lavorato per circa <strong>350 committenti</strong> (ministeri e altri enti statali, regioni, province,
          comuni, consorzi, aziende,...). Numerosi sono i progetti realizzati pressoché in tutte le regioni d’Italia, che hanno
          dimostrato eccellenti risultati e che sono stati presentati a convegni internazionali e corsi universitari.
        </p>
        <div className="jumbotron bg-gradient-light">
          <p className="pt-2">In particolare, dopo aver visitato le più significative esperienze internazionali:</p>
          <ul className="px-4">
            <li>
              in ambito <strong>fluviale</strong> ha progettato, diretto e monitorato applicazioni innovative dell’ingegneria
              naturalistica con obiettivi contestuali di difesa idraulica e riqualificazione dei corsi d’acqua
            </li>
            <li className="pt-2">
              nel campo della riqualificazione delle <strong>dune costiere</strong> ha curato numerosi studi, progetti e piani di
              monitoraggio, specialmente in aree protette nell’ambito di progetti LIFE, MATTM, regionali e di ricerca
            </li>
            <li className="pt-2">
              grazie a decenni di ricognizioni su scala europea, ha curato la progettazione e direzione lavori di svariate{" "}
              <strong>reti escursionistiche e cammini</strong> di interesse nazionale e regionale
            </li>
            <li className="pt-2">
              ha ideato e progettato fra i primi modelli in Italia di <strong>uso efficiente delle risorse</strong> (acqua,
              energia, materiali)
            </li>
            <li className="pt-2">
              è impegnata a trasmettere le proprie esperienze a tecnici e giovani, attraverso programmi di{" "}
              <strong>formazione professionale</strong> e <strong>educazione ambientale</strong>
            </li>
          </ul>
          <p />
        </div>
        <p className="pt-2">
          A tal fine la IRIS dispone di <strong>attrezzature tecniche</strong> specifiche e di uno{" "}
          <strong>staff multidisciplinare</strong>, formato da ingegneri ambientali, agronomi-forestali, architetti, geologi,
          economisti e rilevatori ambientali; inoltre collabora in <strong>partenariato</strong> con numerose società e studi
          professionali al fine di coadiuvare le proprie competenze e di ampliare la conoscenza degli specifici territori in cui
          opera.
        </p>
      </section>
      <section className="la-nostra-sede">
        <div className="row py-5">
          <div className="col-lg-6 align-self-center pr-5">
            <h2>La nostra sede</h2>
            <p className="pt-4">
              Gli uffici della IRIS si trovano a Cerbaia di Sopra, nucleo storico di origine medievale del Comune di San Casciano
              in Val di Pesa, nel Chianti Classico. Siamo circondati dalla campagna, viti, olivi, boschi e coltivi e prossimi al
              Fiume Pesa e fra due suoi affluenti.
            </p>
            <p>
              L'edificio che ci ospita è stato ristrutturato con criteri di bioedilizia: tetto isolato in sughero, riscaldamento a
              biomasse, illuminazione con tunnel solare, recupero di materiali originari, trattamenti delle superfici con vernici
              naturali, arredi in legno impregnato con olii e cere vegetali.
            </p>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <div id="carouselIndicator" className="carousel slide" data-interval="false" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselIndicator" data-slide-to={0} className="active" />
                <li data-target="#carouselIndicator" data-slide-to={1} />
                <li data-target="#carouselIndicator" data-slide-to={2} />
                <li data-target="#carouselIndicator" data-slide-to={3} />
                <li data-target="#carouselIndicator" data-slide-to={4} />
                <li data-target="#carouselIndicator" data-slide-to={5} />
                <li data-target="#carouselIndicator" data-slide-to={6} />
                <li data-target="#carouselIndicator" data-slide-to={7} />
                <li data-target="#carouselIndicator" data-slide-to={8} />
                <li data-target="#carouselIndicator" data-slide-to={9} />
                <li data-target="#carouselIndicator" data-slide-to={10} />
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/IMGP3461.jpg").default}
                    alt="La nostra sede 1"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/MAUB0635.jpg").default}
                    alt="La nostra sede 2"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/MBSU1288.jpg").default}
                    alt="La nostra sede 3"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Isolamento del tetto in sughero</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/MBSU1269.jpg").default}
                    alt="La nostra sede 4"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/MBAX6766.jpg").default}
                    alt="La nostra sede 5"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Arredi in materiali naturali</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/MBAX6767.jpg").default}
                    alt="La nostra sede 6"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/P2263864.jpg").default}
                    alt="La nostra sede 7"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Illuminazione con tunnel solare</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/P2263866.jpg").default}
                    alt="La nostra sede 8"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Caldaia a biomasse</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/P2263867.jpg").default}
                    alt="La nostra sede 9"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Illuminazione ad alta efficienza</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/P2263868.jpg").default}
                    alt="La nostra sede 10"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../../img/chi-siamo/la-societa/la-nostra-sede/P2263870.jpg").default}
                    alt="La nostra sede 11"
                    className="img-fluid rounded"
                  />
                  <div className="carousel-caption d-block">
                    <p>Ventilazione naturale e sistemi radianti</p>
                  </div>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselIndicator" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselIndicator" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="no-profit">
        <div className="no-profit-line">
          <h2 className="pt-4">No profit</h2>
        </div>
        <p className="pt-4">
          La IRIS è socia fondatrice di Banca Etica ed è impegnata nella solidarietà, fornendo collaborazione ad associazioni,
          fondazioni e ONLUS operanti nei settori ambientale, sociale, umanitario e della ricerca medica. Da anni devolve una
          quota del reddito a Organizzazioni non Lucrative di Utilità Sociale (D.L. g.s. 14/11/97).
        </p>
        <button className="btn btn-light font-weight-bold" type="button" data-toggle="collapse" data-target="#donazioni">
          In materia di deducibilità delle donazioni si riportano informazioni tratte dal DPR 917/1986{" "}
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
        <div className="collapse" id="donazioni">
          <div className="card card-body">
            <p>
              <strong>PER LE SOCIETÀ</strong>: Art. 65 – Oneri di utilità sociale Le spese relative a opere e servizi utilizzabili
              dalla generalità dei dipendenti o categorie di dipendenti volontariamente sostenute per specifiche finalità di
              educazione, istruzione, ricreazione, assistenza sociale e sanitaria o culto sono deducibili per un ammontare
              complessivo non superiore al cinque per mille dell’ammontare delle spese per prestazioni di lavoro dipendente
              risultante dalla dichiarazione dei redditi. Sono inoltre deducibili, per un ammontare complessivamente non superiore
              al 2% del reddito d’impresa dichiarato, le erogazioni liberali fatte a favore di: persone giuridiche che perseguano
              esclusivamente finalità comprese fra quelle indicate dal comma 1 o finalità di ricerca scientifica (…); Onlus;
              università e istituti di istruzione universitaria...
            </p>
            <p>
              <strong>PER PERSONE FISICHE</strong>: Art. 10 (lettera g) – Oneri deducibili Dal reddito complessivo si deducono: i
              contributi, le donazioni e le oblazioni erogati in favore delle organizzazioni non governative idonee ai sensi
              dell’art. 28 della L 26/2/87 n. 49 e delle Onlus, per un importo non superiore al 2% del reddito complessivo
              dichiarato.
            </p>
          </div>
        </div>
      </section>
      <div className="pt-5">
        <img
          className="img-fluid rounded panoramic tramonto"
          src={require("../../img/chi-siamo/la-societa/PB292787.jpg").default}
          alt="Tramonto"
        />
      </div>
    </div>
  );
};

export default LaSocieta;
