import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../../styles/Various.css";

const Team = () => {
  useEffect(() => {
    document.title = "Il Team";
  });
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Team della società formato da varie figure professionali tra cui ingegneri, chimci, architetti, geologi"
        />
      </Helmet>
      <h1 className="pb-5">Il team</h1>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/maurizio-bacci.jpg").default}
                alt="Maurizio Bacci"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Maurizio Bacci</h4>
            <h6 className="text-muted">Ingegnere ambientale</h6>
            <p className="pt-2">
              Fondatore, amministratore unico e direttore tecnico della IRIS, con oltre 30 anni di esperienza su problematiche
              inerenti: VIA, VAS, ingegneria naturalistica, efficienza energetica, riqualificazione fluviale, turismo e mobilità
              sostenibili, formazione. Ha coperto ruoli per istituzioni nazionali e regionali, redatto numerosi progetti, studi e
              direzione lavori, svolto docenze universitarie e pubblicazioni. È stato membro della Commissione Nazionale VIA VAS
              grandi opere. È fondatore del Centro Italiano per la Riqualificazione Fluviale e da 40 anni collabora con
              associazioni ambientaliste. È presidente dell’associazione culturale Il Magnifico.
              <br />
              <a href="/doc/Bacci_Profilo2021.pdf" target="_blank" rel="noopener" className="float-left mt-3">
                <i className="far fa-arrow-alt-circle-down mr-1" />
                CV sintetico
              </a>
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/stefano-corsi.jpg").default}
                alt="Stefano Corsi"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Stefano Corsi</h4>
            <h6 className="text-muted">Ingegnere ambientale</h6>
            <p className="pt-2">
              Ha svolto attività di progettazione, studi e direzione lavori su problematiche inerenti riqualificazione fluviale,
              rischio idraulico, ingegneria naturalistica, valutazioni ambientali, risparmio energetico, rifiuti e fonti
              rinnovabili. Esperto nell’utilizzo di codici di calcolo computerizzati (applicati alla modellazione e
              quantificazione di indicatori), programmi CAD e GIS. Membro della Commissione Ambiente dell’Ordine Ingegneri
              Provincia di Firenze.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/arnaldo-galleri.jpg").default}
                alt="Arnaldo Galleri"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Arnaldo Galleri</h4>
            <h6 className="text-muted">Agronomo</h6>
            <p className="pt-2">
              Specializzato nella gestione e progettazione del verde, del paesaggio e del recupero ambientale. Si occupa della
              gestione tecnica, economica e commerciale di appalti per lavori a verde e forestali. Ha redatto numerosi studi sulla
              vegetazione, progetti di rinaturalizzazione nell’ambito di opere di ingegneria naturalistica e riqualificazione
              ambientale nonché sperimentazioni sulle specie della vegetazione dunale.
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/leonardo-moretti.jpg").default}
                alt="Leonardo Moretti"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Leonardo Moretti</h4>
            <h6 className="text-muted">Geologo</h6>
            <p className="pt-2">
              Specializzato in fotointepretazione. Dal 1983 si è occupato di studi geoambientali (geologici, idraulici, sismici e
              ambientali) a supporto di: pianificazione urbanistica, VIA, VAS e VINCA. Ha inoltre coordinato studi di Impatto
              Ambientale, piani per la raccolta differenziata rifiuti e la localizzazione siti per trattamento rifiuti, bonifica
              siti contaminati e riconversione aree industriali, progetti di sistemazione idraulica e piani di bacino, rilevi
              geomorfologici e di uso del suolo nell’ambito dei Piani di Riassetto Idraulico e Forestale.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/fabio-masi.jpg").default}
                alt="Fabio Masi"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Fabio Masi</h4>
            <h6 className="text-muted">Chimico</h6>
            <p className="pt-2">
              Dottore di ricerca in Scienze Ambientali, direttore tecnico e manager Ricerca&amp;Sviluppo per IRIDRA srl, con 30
              anni di esperienza su problematiche inerenti: qualità acque, depurazione, gestione idrica sostenibile, formazione.
              Ha contribuito ad oltre 500 progetti di fitodepurazione e operato come consulente o ricercatore in Europa, Africa,
              Asia, America. Da 30 anni svolge docenze in corsi e in masters universitari. Ha prodotto circa 100 pubblicazioni
              scientifiche. Dal 2020 è stato nominato IWA Fellow e dirige per la International Water Association il Task Group
              sulle Soluzioni Nature-Based for l'inquinamento delle acque.
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/gianfranco-franchi.jpg").default}
                alt="Gianfranco Franchi"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Gianfranco Franchi</h4>
            <h6 className="text-muted">Architetto paesaggista</h6>
            <p className="pt-2">
              Titolare dello studio FRANCHI+ASSOCIATI. Si occupa di tutti gli aspetti legati alla progettazione del paesaggio: dal
              giardino al paesaggio urbano. In particolare si occupa di parchi pubblici, sistemazioni e riqualificazioni
              ambientali, di studi di impatto ambientale di infrastrutture e attrezzature di interesse pubblico. E’ stato
              professore di Architettura del paesaggio per più di 10 anni all’Università di Genova e vicepresidente di AIAPP dal
              2006 al 2012.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/giuseppe-racioppi.jpg").default}
                alt="Giuseppe Racioppi"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Giuseppe Racioppi</h4>
            <h6 className="text-muted">Ingegnere energetico</h6>
            <p className="pt-2">
              Esperto di tematiche relative a efficienza energetica e fonti rinnovabili. Negli ultimi anni ha seguito diversi
              progetti di efficientamento energetico, nel settore industriale e terziario. In passato ha accumulato diversi anni
              di esperienza nel campo dell’ingegneria dell’affidabilità e manutenzione nel settore oil&amp;gas.
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/damiano-iannetti.jpg").default}
                alt="Damiano Iannetti"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Damiano Iannetti</h4>
            <h6 className="text-muted">Architetto - bioedilizia</h6>
            <p className="pt-2">
              Esperto di Bioarchitettura ed efficienza energetica, consulente CasaClima. Negli ultimi 15 anni ha seguito diversi
              progetti di riqualificazione ed efficientamento energetico, nel settore residenziale. In particolare si è
              specializzato nello sviluppo di progetti di co-housing e autocostruzione, seguendo i principi della bioarchitettura
              e della progettazione partecipata.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/andrea-giacomelli.jpg").default}
                alt="Andrea Giacomelli"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Andrea Giacomelli</h4>
            <h6 className="text-muted">Ingegnere ambientale - Gis modellistica </h6>
            <p className="pt-2">
              Dottore di ricerca in Idrologia, con esperienza pluriennale sia in campo industriale che di ricerca nel settore dei
              sistemi informativi territoriali e nella gestione dati ambientali. Dal 2006 ha anche avviato diversi progetti nel
              settore della valorizzazione di risorse minori in ambito culturale, ambientale e dell’innovazione libera.
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/luca-antonini.jpg").default}
                alt="Luca Antonini"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Luca Antonini</h4>
            <h6 className="text-muted">Economista</h6>
            <p className="pt-2">
              Laureato in economia, agisce nella gestione di imprese sociali ed enti del terzo settore, alla costante ricerca dei
              possibili equilibri tra le fattibilità economico-finanziarie e quelle socio-ambientali. In precedenza aveva
              ricoperto incarichi di responsabile di progetto, soprattutto in progetti finanziati dalle istituzioni comunitarie o
              regionali, nei settori dello sviluppo locale, della gestione del territorio e per la tutela del pianeta. E' inoltre
              stato imprenditore in una PMI attiva nel settore della meccanica e della robotica. È procuratore della IRIS.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/federico-bacci.jpg").default}
                alt="Federico Bacci"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Federico Bacci</h4>
            <h6 className="text-muted">Geologo - pilota droni</h6>
            <p className="pt-2">
              Specializzato in Geologia Applicata, con particolare riferimento a: monitoraggio ambientale per grandi
              infrastrutture, modellazione geotecnica e idrogeologica e rilievo del territorio con SAPR. Esperienza decennale
              monitoraggio acque sotterranee per progetto Terzo Valico dei Giovi tratta Milano-Genova. Nel campo del rilevamento
              del territorio, in particolare con tecnica aerofotogrammetrica e termografica: solida esperienza in ricostruzione e
              analisi fenomeni gravitativi, monitoraggio attività estrattive e siti di discarica e dinamiche erosive costiere.
              Collabora con “Scuola Droni Genova” come docente di aerofotogrammetria.
            </p>
          </div>
        </div>
        <div className="row align-items-center pb-5 flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/francesca-bacci.jpg").default}
                alt="Francesca Bacci"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Francesca Bacci</h4>
            <h6 className="text-muted">Architetto</h6>
            <p className="pt-2">
              Impegnata sui temi della riqualificazione energetica, materiali naturali e attenzione al contesto. Ha collaborato
              con vari studi di architettura e ingegneria maturando esperienze nel campo della progettazione edilizia e delle
              normative tecniche e urbanistiche. Ha conseguito la qualifica di operatore termografico di secondo livello per il
              settore architettonico. Concilia le passioni per arte, scienza e natura con la professione e spazia nell’ambito
              della grafica e del design.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center pb-5">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/daniele-maffi.jpg").default}
                alt="Daniele Maffi"
                className="rounded float-lg-left team-img"
              />
            </div>
          </div>
          <div className="col-lg-9">
            <h4>Daniele Maffi</h4>
            <h6 className="text-muted">Ingegnere ambientale junior</h6>
            <p className="pt-2">
              Ha svolto tirocinio formativo presso la IRIS. Conosce l’utilizzo degli applicativi principali in materia di
              progettazione, in particolare GIS. Tesi di laurea, conseguita nell’estate 2018, circa l’idrodinamica dei canali
              vegetati.
            </p>
          </div>
        </div>
        <div className="row align-items-center flex-md-row-reverse">
          <div className="col-lg-3">
            <div className="pb-4">
              <img
                src={require("../../img/chi-siamo/il-team/jacopo-bacci.jpg").default}
                alt="Jacopo Bacci"
                className="rounded float-lg-right team-img"
              />
            </div>
          </div>
          <div className="col-lg-9 text-lg-right">
            <h4>Jacopo Bacci</h4>
            <h6 className="text-muted">Web developer - musicista</h6>
            <p className="pt-2">
              Laureato al Conservatorio G. B. Martini di Bologna in Musica Elettronica, ha composto, suonato e pubblicato brani
              musicali nei generi ambient, musica elettroacustica e techno.
              <br /> Successivamente si è specializzato in sviluppo web e web design.
              <br />
              Per la IRIS ha collaborato ad alcuni progetti multiartistici ispirati all'ambiente e prodotto l'attuale sito web.
              <br /> E' il punto di riferimento della società per lo sviluppo di nuove tecnologie e la gestione informatica.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
