import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_HOVER_CDN } from "../../utils/constants";

const ServiziFormazione = () => {
  useEffect(() => {
    document.title = "Servizi - Formazione";
  });
  return (
    <div className="ServiziFormazione">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta
          name="description"
          content="corsi universitari a contratto, lezioni a master post laurea, lezioni a corsi specialistici, seminari tematici, lezioni tematiche scolastiche, workshop legate a progetti, educazione ambientale al pubblico, opere artistiche e culturali"
        />
      </Helmet>
      <h1 className="pb-4">Formazione</h1>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h4>Tipologie</h4>
            <ul className="px-4 py-2">
              <li>corsi universitari a contratto</li>
              <li>lezioni a master post laurea</li>
              <li>lezioni a corsi specialistici</li>
              <li>seminari tematici</li>
              <li>lezioni tematiche scolastiche</li>
              <li>workshop legate a progetti</li>
              <li>educazione ambientale al pubblico</li>
              <li>opere artistiche e culturali</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/formazione/formaz1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/formazione/formaz2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center flex-md-row-reverse pt-5 pb-5">
          <div className="col-md-5 offset-md-1">
            <h4>Committenti e destinatari</h4>
            <ul className="px-4 py-2">
              <li>Università e istituti di ricerca</li>
              <li>Agenzie formative</li>
              <li>Enti pubblici</li>
              <li>Associazioni scientifiche e culturali</li>
              <li>Associazioni e scuole di categoria</li>
              <li>Scuole primarie e secondarie</li>
              <li>Ordini professionali</li>
              <li>Cittadini e gruppi</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/formazione/formaz3.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/formazione/formaz4.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <h4>Metodologia</h4>
      <section>
        <p className="py-2">
          Una delle nostre principali missioni è trasmettere la nostra esperienza mostrando i lavori che abbiamo realizzato. A
          fianco alla comunicazione scientifica e tecnica di base e specifica si cerca infatti di coinvolgere il più possibile i
          partecipanti agli eventi formativi attraverso la conoscenza diretta di esempi concreti tramite visite didattiche ed
          esperienziali.
        </p>
        <p>
          <strong>Educazione percettiva</strong> significa la fusione contestuale e sinergica di scienza e sensi, un approccio
          esplorativo e basato su osservazione, creatività ed emozione, per un apprendimento efficace ma, soprattutto, per
          acquisire sensibilità e passione al rispetto e alla tutela della natura.
        </p>
        <blockquote className="blockquote">
          <p className="mb-0">
            <em>
              “Nessuna ricostruzione, per bella che sia, può essere veritiera perché non può riprodurre la vita del paesaggio, la
              caduta dell’acqua, il tremolio delle foglie, il canto degli uccelli, il profumo dei fiori, le forme cangianti delle
              nubi: per conoscere è necessario vedere.”
            </em>
          </p>
          <footer className="blockquote-footer text-right">Eliseé Reclus, geografo educatore, 1855</footer>
        </blockquote>
        <p>
          <strong>Esempio:</strong>{" "}
          <a href="http://h2oltre.com/" target="_blank" rel="noopener">
            www.h2oltre.com
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" />
          </a>
        </p>
      </section>
    </div>
  );
};

export default ServiziFormazione;
