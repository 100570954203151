export default [
  // Acque e suolo
  {
    id: "coste-dune",
    category: "Acque e suolo",
    title: "Coste e dune",
    imgs: [
      {
        path1: "acque-e-suolo/coste-dune/Baratti.jpg",
        path2: "acque-e-suolo/coste-dune/Baratti2.jpg",
        description: "Ripristino morfologico e difesa di un tratto costiero <br> con tecniche di ingegneria naturalistica",
        location: "(Comune di Piombino - Golfo di Baratti - LI)",
      },
      {
        path1: "acque-e-suolo/coste-dune/Bolgheri.jpg",
        path2: "acque-e-suolo/coste-dune/Bolgheri2.jpg",
        description: "Sistemazione idraulico-ambientale di sbocco a mare <br> con tecniche di ingegneria naturalistica",
        location: "(Comune di Castagneto Carducci - Fosso di Bolgheri - LI)",
      },
      {
        path1: "acque-e-suolo/coste-dune/Lacona.jpg",
        path2: "acque-e-suolo/coste-dune/Lacona2.jpg",
        description: "Riqualificazione sistema dunale con tecniche di ingegneria naturalistica - Progetto RESTO CON LIFE",
        location: "(Ente Parco Nazionale Arcipelago Toscano - Lacona, Isola d’Elba - LI)",
        link: "/doc/CapitoloB-Allegato2_scheda_Lacona.pdf",
      },
      {
        path1: "acque-e-suolo/coste-dune/Melendugno.jpg",
        path2: "acque-e-suolo/coste-dune/Melendugno2.jpg",
        description:
          "Riqualificazione del tratto costiero: recupero paesaggistico-ambientale dei tratti degradati, messa in sicurezza, miglioramento della fruizione",
        location: "(Comune di Melendugno - S. Foca, Roca, S. Andrea Nord e Sud - LE)",
      },
      {
        path1: "acque-e-suolo/coste-dune/PortoCesareo.jpg",
        path2: "acque-e-suolo/coste-dune/PortoCesareo2.jpg",
        description: "Ricostruzione, difesa e riqualificazione della fascia dunale",
        location: "(Ente Area Marina Protetta di Porto Cesareo - LC)",
        link: "/doc/CapitoloB-Allegato3_scheda_PortoCesareo.pdf",
      },
      {
        path1: "acque-e-suolo/coste-dune/Sterpaia.jpg",
        path2: "acque-e-suolo/coste-dune/Sterpaia2.jpg",
        description:
          "Difesa e riqualificazione geomorfologica e ambientale del sistema dunale con tecniche di ingegneria naturalistica",
        location: "(Provincia di Livorno e Comune di Piombino - <br> Costa Est Golfo di Follonica/Parco della Sterpaia - LI)",
        link: "/doc/Brochure_RipristinoDune_Sterpaia.pdf",
      },
      {
        path1: "acque-e-suolo/coste-dune/Portopino.jpg",
        path2: "acque-e-suolo/coste-dune/Portopino2.jpg",
        description: "Riqualificazione sistema dunale con tecniche di ingegneria naturalistica - PROGETTO LIFE SOSS DUNES",
        location: "(Comune di Sant’Anna Arresi - Porto Pino - CA)",
      },
      {
        path1: "acque-e-suolo/coste-dune/Rimigliano.jpg",
        path2: "acque-e-suolo/coste-dune/Rimigliano2.jpg",
        description:
          "Difesa e riqualificazione geomorfologica e ambientale del sistema dunale <br> con tecniche di ingegneria naturalistica",
        location: "(Provincia di Livorno - Parco costiero di Rimigliano - LI)",
      },
    ],
  },
  {
    id: "erosione-fluviale",
    category: "Acque e suolo",
    title: "Erosione fluviale",
    imgs: [
      {
        path1: "acque-e-suolo/erosione-fluviale/ComuneDozza.jpg",
        path2: "acque-e-suolo/erosione-fluviale/ComuneDozza2.jpg",
        description:
          "Riqualificazione idraulico-ambientale di torrenti in aree collinari e appenniniche con tecniche di ingegneria naturalistica",
        location: "(Consorzio Bonifica Colline del Chianti e Comune di Dozza)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/Budrione.jpg",
        path2: "acque-e-suolo/erosione-fluviale/Budrione2.jpg",
        description: "Sistemazione idraulico-ambientale di canali <br> con tecniche di ingegneria naturalistica",
        location: "(Consorzio Bonifica Parmigiana Moglia Secchia - <br> Cavo Migliarina, Cavo Lama, Fossetta dei Morti - RE)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/Cesto.jpg",
        path2: "acque-e-suolo/erosione-fluviale/Cesto2.jpg",
        description: "Difesa spondale e rivitalizzazione fluviale <br> con tecniche di ingegneria naturalistica",
        location: "(Comune di Figline Valdarno - Torrente Cesto - FI)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/corbara.jpg",
        path2: "acque-e-suolo/erosione-fluviale/corbara2.jpg",
        description:
          "Messa in sicurezza di versante su area di dissipazione diga idroelettrica con tecniche di ingegneria naturalistica",
        location: "(EON SpA - Fiume Tevere, Serbatoio di Corbara - PG)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/esino.jpg",
        path2: "acque-e-suolo/erosione-fluviale/esino2.jpg",
        description: "Interventi di difesa idraulica con tecniche di ingegneria naturalistica",
        location: "(Comune Jesi - Fiume Esino, AN)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/Pesa.jpg",
        path2: "acque-e-suolo/erosione-fluviale/Pesa2.jpg",
        description: "Difese spondali con ingegneria naturalistica",
        location: "(Consorzio di Bonifica Colline Chianti, Fiume Pesa, FI)",
        link: "/doc/Pesa.pdf",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/Ponterosso.jpg",
        path2: "acque-e-suolo/erosione-fluviale/Ponterosso2.jpg",
        description: "Consolidamento spondale e rivitalizzazione fluviale <br> con tecniche di ingegneria naturalistica",
        location: "(Comune di Figline Valdarno - Borro Ponterosso - FI)",
      },
      {
        path1: "acque-e-suolo/erosione-fluviale/Vicchio.jpg",
        path2: "acque-e-suolo/erosione-fluviale/Vicchio2.jpg",
        description:
          "Sistemazione idraulico-ambientale dell’alveo e difesa spondale <br> con tecniche di ingegneria naturalistica",
        location: "(Comune di Vicchio - Fiume Sieve, loc. Ginestra-Caparciano - FI)",
      },
    ],
  },
  {
    id: "qualita-acque",
    category: "Acque e suolo",
    title: "Qualità delle acque",
    imgs: [
      {
        path1: "acque-e-suolo/qualita-acque/Gorgona.jpg",
        path2: "acque-e-suolo/qualita-acque/Gorgona2.jpg",
        description: "Trattamento mediante fitodepurazione dei reflui e loro totale riuso plurimo",
        location: "(Ministero di Grazia e Giustizia - Isola di Gorgona - LI)",
      },
      {
        path1: "acque-e-suolo/qualita-acque/Jesi.jpg",
        path2: "acque-e-suolo/qualita-acque/Jesi2.jpg",
        description: "Impianto di fitodepurazione post-trattamento <br> dei reflui civili e industriali 70.000 ab.eq.",
        location: "(Consorzio Gorgovivo - Comune di Jesi - AN)",
      },
      {
        path1: "acque-e-suolo/qualita-acque/Pesa.jpg",
        path2: "acque-e-suolo/qualita-acque/Pesa2.jpg",
        description:
          "Progetto Citizen Science - Fresh Water Watch Research Manager: Osservatorio cittadino per il monitoraggio di ecosistemi acquatici locali",
        location: "(Università di Firenze, DAGRI - Fiume Pesa a San Casciano - FI)",
      },
      {
        path1: "acque-e-suolo/qualita-acque/Publiser.jpg",
        path2: "acque-e-suolo/qualita-acque/Publiser2.jpg",
        description: "Impiantini pilota di post-trattamento con fitodepurazione, progetto di ricerca",
        location: "(Publiser - Empoli, FI)",
      },
      {
        path1: "acque-e-suolo/qualita-acque/Vernise.jpg",
        path2: "acque-e-suolo/qualita-acque/Vernise2.jpg",
        description: "Riduzione apporto nutrienti, riqualificazione ambientale e difesa idraulica",
        location: "(Consorzio Bonifica Dese Sile - Scolo Zermason, Rete scolante laguna di Venezia)",
      },
      {
        path1: "acque-e-suolo/qualita-acque/Zermason.jpg",
        path2: "acque-e-suolo/qualita-acque/Zermason2.jpg",
        description: "Riduzione apporto nutrienti, riqualificazione ambientale e difesa idraulica",
        location: "(Cons Bon Acque Risorgive - Scolo Vernise, Rete scolante laguna di Venezia)",
      },
    ],
  },
  {
    id: "zone-umide",
    category: "Acque e suolo",
    title: "Zone umide",
    imgs: [
      {
        path1: "acque-e-suolo/zone-umide/Accesa.jpg",
        path2: "acque-e-suolo/zone-umide/Accesa2.jpg",
        description:
          "Riqualificazione idraulico-ambientale e gestione fruizione con tecniche di ingegneria naturalistica, finanziamenti settore pesca",
        location: "(Comune di Massa Marittima - Lago dell’Accesa - GR)",
      },
      {
        path1: "acque-e-suolo/zone-umide/amendolea.jpg",
        path2: "acque-e-suolo/zone-umide/amendolea2.jpg",
        description: "Progetto tutela e incremento habitat ululone dal ventre giallo",
        location: "(Ente Parco Nazionale Aspromonte - Fiumara Amendolea, RC)",
      },
      {
        path1: "acque-e-suolo/zone-umide/Bolano.jpg",
        path2: "acque-e-suolo/zone-umide/Bolano2.jpg",
        description: "Creazione habitat umido perifluviale con finalità naturalistiche e fruitive",
        location: "(Comunità Montana Alta Val Vara - F. Magra, Bolano SP)",
      },
      {
        path1: "acque-e-suolo/zone-umide/Porta.jpg",
        path2: "acque-e-suolo/zone-umide/Porta2.jpg",
        description: "Cassa di espansione Torrente Versilia: riqualificazione ambientale Lago di Porta",
        location: "(Regione Toscana - Versilia, MS)",
      },
      {
        path1: "acque-e-suolo/zone-umide/Querciola.jpg",
        path2: "acque-e-suolo/zone-umide/Querciola2.jpg",
        description: "Recupero a fini naturalistici e gestione sostenibile fruizione in area protetta",
        location: "(Comune di Quarrata - Lago di Zela - PT)",
      },
      {
        path1: "acque-e-suolo/zone-umide/Trasimeno.jpg",
        path2: "acque-e-suolo/zone-umide/Trasimeno2.jpg",
        description: "Valutazione degli interventi per la soluzione del problema <br> accumulo sedimenti: analisi multicriterio",
        location: "(Università di Perugia - Lago Trasimeno - PG)",
      },
    ],
  },
  {
    id: "versanti",
    category: "Acque e suolo",
    title: "Versanti",
    imgs: [
      {
        path1: "acque-e-suolo/versanti/BotriVolterra.jpg",
        path2: "acque-e-suolo/versanti/BotriVolterra2.jpg",
        description: "Sistemazione idraulico-forestale, con tecniche <br> di ingegneria naturalistica, di sottobacini montani",
        location: "(Comunità Montana Alta Val di Cecina - <br> Botri Pagliaio e Pollaio presso Volterra - PI)",
      },
      {
        path1: "acque-e-suolo/versanti/Campione.jpg",
        path2: "acque-e-suolo/versanti/Campione2.jpg",
        description: "Sistemazione idraulica di torrente alpino con tecniche di ingegneria naturalistica",
        location: "(Comune di Campione d’Italia - Torrente Giarone, affluente Lago di Lugano - CO)",
      },
      {
        path1: "acque-e-suolo/versanti/Fraggina.jpg",
        path2: "acque-e-suolo/versanti/Fraggina2.jpg",
        description: "Riassetto idrogeologico di torrente in ambiente calanchivo<br> con tecniche di ingegneria naturalistica",
        location: "(Consorzio di Bonifica Valdera - Torrente Fraggina presso Volterra - PI)",
      },
      {
        path1: "acque-e-suolo/versanti/Pomonte.jpg",
        path2: "acque-e-suolo/versanti/Pomonte2.jpg",
        description:
          "Sistemazione idraulica con tecniche di ingegneria naturalistica,<br> Programma di Ricerca interventi sperimentali, Ob. 5/b reg. UE 2081",
        location: "(Provincia di Livorno -  Isola d’Elba, Fosso di Pomonte - LI)",
      },
      {
        path1: "acque-e-suolo/versanti/Pratomagno.jpg",
        path2: "acque-e-suolo/versanti/Pratomagno2.jpg",
        description: "Sistemazioni idrauliche per attraversamenti di viabilità<br> su torrenti con ingegneria naturalistica",
        location: "(Unione Comuni Montani Pratomagno -<br> Loc. Roveraia nel Comune di Loro Ciuffenna - AR)",
      },
      {
        path1: "acque-e-suolo/versanti/Sardegna.jpg",
        path2: "acque-e-suolo/versanti/Sardegna2.jpg",
        description: "Linee guida per l'applicazione dell'ingegneria naturalistica sul territorio regionale.",
        location: "(Regione Sardegna)",
      },
      {
        path1: "acque-e-suolo/versanti/Sieve.jpg",
        path2: "acque-e-suolo/versanti/Sieve2.jpg",
        description: "Difesa idraulica a protezione di viabilità ciclabile lungo fascia riparia.",
        location: "(Comune di Pontassieve - Fiume Arno, loc. Le Sieci - FI)",
      },
    ],
  },

  // Territorio
  {
    id: "vas",
    category: "Territorio",
    title: "VAS",
    imgs: [
      {
        path1: "territorio/vas/MT.jpg",
        path2: "territorio/vas/MT2.jpg",
        description:
          "Rapporto Ambientale VAS ai sensi LR 10/2010 del Piano di Recupero<br> e variante al RU complesso Ex Manifattura Tabacchi",
        location: "(Manifattura Tabacchi srl - Firenze)",
      },
      {
        path1: "territorio/vas/fendi.jpg",
        path2: "territorio/vas/fendi2.jpg",
        description:
          "Fendi, new pole of excellence: relazione ambientale procedura Piano Attuativo in Variante semplificata al PS e RU - verifica di assoggettabilità a VAS",
        location: "(Fendi SpA - Bagno a Ripoli, FI)",
      },
      {
        path1: "territorio/vas/Spiagge.jpg",
        path2: "territorio/vas/Spiagge2.jpg",
        description: "Piano di Utilizzazione arenili: Verifica di assoggettabilità a VAS<br> ai sensi della LR 10/2010",
        location: "(Comune di San Vincenzo - LI)",
      },
      {
        path1: "territorio/vas/ISC.jpg",
        path2: "territorio/vas/ISC2.jpg",
        description:
          "Variante al Regolamento Urbanistico Comunale: Rapporto Ambientale VAS<br> ai sensi LR 10/2010 zona ex Conservificio",
        location: "(ISC SpA - San Vincenzo, LI)",
      },
      {
        path1: "territorio/vas/Produttive.jpg",
        path2: "territorio/vas/Produttive2.jpg",
        description: "Progetto di Urbanizzazione area nord di San Vincenzo:<br> Studio di VAS ordinaria per Variante Urbanistica",
        location: "(ISC Spa - Livorno)",
      },
      {
        path1: "territorio/vas/PTA.jpg",
        path2: "territorio/vas/PTA2.jpg",
        description:
          "Rapporto Ambientale di VAS del Piano di Tutela Regionale Acque (PTA),<br> ai sensi dell’art. 36 del Dlgs 50/2016",
        location: "(Regione Toscana)",
      },
    ],
  },
  {
    id: "via",
    category: "Territorio",
    title: "V.I.A.",

    imgs: [
      {
        path1: "territorio/via/Cogne.jpg",
        path2: "territorio/via/Cogne2.jpg",
        description: "Trattamento polveri tossico-nocive da acciaieria ILVA Cogne",
        location: "(Ekogorund SpA, Aosta)",
      },
      {
        path1: "territorio/via/Strada.jpg",
        path2: "territorio/via/Strada2.jpg",
        description: "Variante SP 12 Empolese",
        location: "(Provincia di Firenze, San Vincenzo a Torri, Scandicci, FI)",
      },
      {
        path1: "territorio/via/Ombrone.jpg",
        path2: "territorio/via/Ombrone2.jpg",
        description: "Progetto per la difesa costiera presso foce F. Ombrone",
        location: "(Ente Parco Reg della Maremma,- GR)",
      },
      {
        path1: "territorio/via/Golf.jpg",
        path2: "territorio/via/Golf2.jpg",
        description: "Progetto impianto da golf internazionale",
        location: "(Manzano Sviluppo srl - Cortona, AR)",
      },
      {
        path1: "territorio/via/Vara.jpg",
        path2: "territorio/via/Vara2.jpg",
        description: "Interventi per la difesa idraulica e la riqualificazione geomorfologica e ambientale",
        location: "(Autorità Bacino Interreg. Magra - Fiume Vara, SP)",
      },
      {
        path1: "territorio/via/Arbia.jpg",
        path2: "territorio/via/Arbia2.jpg",
        description: "Progetti di casse di espansione per la difesa da eventi alluvionali",
        location: "(Provincia di Siena - T. Arbia, SI)",
      },
      {
        path1: "territorio/via/Multicriterio.jpg",
        path2: "territorio/via/Multicriterio2.jpg",
        description: "Variante SP 12 Empolese - Analisi Multicriterio alternative di tracciato",
        location: "(Provincia di Firenze - San Vincenzo a Torri, Scandicci - FI)",
      },
      {
        path1: "territorio/via/Geotermia.jpg",
        path2: "territorio/via/Geotermia2.jpg",
        description: "Progetto postazione di sondaggio geotermico e misure di mitigazione",
        location: "(ENEL Geotermia - Castelnuovo di Val di Cecina - PI)",
      },
    ],
  },
  {
    id: "vinca",
    category: "Territorio",
    title: "V.INC.A.",

    imgs: [
      {
        path1: "territorio/vinca/Esino.jpg",
        path2: "territorio/vinca/Esino2.jpg",
        description: "Interventi per la riduzione del rischio idraulico",
        location: "(Comune di Jesi - F. Esino, AN)",
      },
      {
        path1: "territorio/vinca/Desesile.jpg",
        path2: "territorio/vinca/Desesile2.jpg",
        description: "Interventi strutturali in rete minore di Bonifica",
        location: "(Cons Bon Acque Risorgive - provincia di Venezia)",
      },
      {
        path1: "territorio/vinca/Monticello.jpg",
        path2: "territorio/vinca/Monticello2.jpg",
        description: "Progetto nuovo Villaggio di Monticello",
        location: "(Monticello s.r.l. - Terranuova Bracciolini, AR)",
      },
      {
        path1: "territorio/vinca/Vara.jpg",
        path2: "territorio/vinca/Vara2.jpg",
        description: "Interventi idraulico-ambientali per la difesa e riqualificazione fluviale",
        location: "(Autorità Bacino Interreg. Magra - Fiume Vara, SP)",
      },
      {
        path1: "territorio/vinca/PTA.jpg",
        path2: "territorio/vinca/PTA2.jpg",
        description: "Piano di Tutela Regionale Acque (PTA), ai sensi dell’art. 36 del Dlgs 50/2016",
        location: "(Regione Toscana)",
      },
    ],
  },
  {
    id: "relazioni-paesaggistiche",
    category: "Territorio",
    title: "Relazioni paesaggistiche",

    imgs: [
      {
        path1: "territorio/relazioni-paesaggistiche/Carpugnane.jpg",
        path2: "territorio/relazioni-paesaggistiche/Carpugnane2.jpg",
        description: "Interventi per la realizzazione del Parco comunale delle Carpugnane",
        location: "(Comune di Calenzano - FI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Trieste.jpg",
        path2: "territorio/relazioni-paesaggistiche/Trieste2.jpg",
        description: "Itinerari turistici: interventi di realizzazione cartellonistica e totem informativi",
        location: "(Provincia di Trieste)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Vernise.jpg",
        path2: "territorio/relazioni-paesaggistiche/Vernise2.jpg",
        description: "Interventi di riqualificazione idraulico-ambientale",
        location: "(Cons Bon Acque Risorgive - Scolo Vernise, VE)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/puntala.jpg",
        path2: "territorio/relazioni-paesaggistiche/puntala2.jpg",
        description: "Recupero e riequilibrio litorale di Punta Ala:<br> fotosimulazione interventi e progettazione",
        location: "(Comune di Castiglione della Pescaia - GR)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Rimigliano.jpg",
        path2: "territorio/relazioni-paesaggistiche/Rimigliano2.jpg",
        description: "Difesa e riqualificazione geomorfologica e ambientale del sistema dunale",
        location: "(Provincia di Livorno - Parco costiero di Rimigliano - LI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Discarica.jpg",
        path2: "territorio/relazioni-paesaggistiche/Discarica2.jpg",
        description:
          "Bonifica discarica rifiuti solidi - raccolta percolato, drenaggio e trattamento biogas, confinamento tramite capping, riprofilature morfologiche e riqualificazione ambientale",
        location: "(Comune di S. Vincenzo - Loc. Le Ginepraie - LI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Fraggina.jpg",
        path2: "territorio/relazioni-paesaggistiche/Fraggina2.jpg",
        description: "Riassetto idrogeologico di corso d’acqua torrente",
        location: "(Consorzio di Bonifica Valdera - Torrente Fraggina presso Volterra - PI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Lacona.jpg",
        path2: "territorio/relazioni-paesaggistiche/Lacona2.jpg",
        description: "Riqualificazione sistema dunale - Progetto RESTO CON LIFE",
        location: "(Ente Parco Nazionale Arcipelago Toscano - Lacona, Isola d’Elba - LI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Portopino.jpg",
        path2: "territorio/relazioni-paesaggistiche/Portopino2.jpg",
        description: "Riqualificazione sistema dunale - PROGETTO LIFE SOSS DUNES",
        location: "(Comune di Sant’Anna Arresi - Porto Pino - CA)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/SanVincenzo.jpg",
        path2: "territorio/relazioni-paesaggistiche/SanVincenzo2.jpg",
        description: "Studio per sistema di produzione energetica con generatori eolici",
        location: "(Comune di San Vincenzo - Porto turistico - LI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Strada.jpg",
        path2: "territorio/relazioni-paesaggistiche/Strada2.jpg",
        description: "Progetto di Variante SP 12 Empolese",
        location: "(Provincia di Firenze - San Vincenzo a Torri, Scandicci - FI)",
      },
      {
        path1: "territorio/relazioni-paesaggistiche/Zermason.jpg",
        path2: "territorio/relazioni-paesaggistiche/Zermason2.jpg",
        description: "Progetto riqualificazione fluviale e risanamento qualità acque",
        location: "(Consorzio Bonifica Dese Sile - Scolo Zermason, Rete scolante laguna di Venezia)",
      },
    ],
  },
  {
    id: "piani-territoriali-urbanistici",
    category: "Territorio",
    title: "Piani territoriali e urbanistici",
    imgs: [
      {
        path1: "territorio/piani-territoriali-urbanistici/ProvFI.jpg",
        path2: "territorio/piani-territoriali-urbanistici/ProvFI2.jpg",
        description: "Piano Territoriale di Coordinamento Provinciale (PTCP)",
        location: "(Provincia Firenze - Area Val di Greve)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/termoli.jpg",
        path2: "territorio/piani-territoriali-urbanistici/termoli2.jpg",
        description: "Piano urbanistico comunale: studio vulnerabilità ambientale",
        location: "(Comune di Termoli - CB)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/lerici.jpg",
        path2: "territorio/piani-territoriali-urbanistici/lerici2.jpg",
        description: "Piano regolatore comunale: studio mitigazioni ambientali",
        location: "(Comune di Lerici - SP)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/piombino.jpg",
        path2: "territorio/piani-territoriali-urbanistici/piombino2.jpg",
        description:
          "Pianto Strutturale Intercomunale Piombino e Campiglia Marittima<br> - Analisi e valutazioni settore ambientale",
        location: "(Piombino - LI)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/Accesa.jpg",
        path2: "territorio/piani-territoriali-urbanistici/Accesa2.jpg",
        description: "Indagine ambientale finalizzata all’istituzione per un'area protetta<br> per il Lago dell’Accesa",
        location: "(Comune di Massa Marittima (GR)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/Liguria.jpg",
        path2: "territorio/piani-territoriali-urbanistici/Liguria2.jpg",
        description: "Metodologie di riqualificazione fluviale per i corsi d’acqua liguri",
        location: "(Centro Italiano per la Riqualificazione Fluviale per Regione Liguria)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/Marche.jpg",
        path2: "territorio/piani-territoriali-urbanistici/Marche2.jpg",
        description:
          "Applicazione tecniche di ingegneria naturalistica per la sistemazione del suolo<br> in aree interessate da eventi sismici",
        location: "(Regione Marche e WWF Italia)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/ProvMI.jpg",
        path2: "territorio/piani-territoriali-urbanistici/ProvMI2.jpg",
        description:
          "Piano Territoriale di Coordinamento Provinciale: Linee guida per interventi di ingegneria naturalistica lungo i corsi d’acqua",
        location: "(Provincia di Milano)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/Umbria.jpg",
        path2: "territorio/piani-territoriali-urbanistici/Umbria2.jpg",
        description:
          "Piani di Gestione Parchi Regionali Fiumi Tevere e Nera, Laghi Trasimeno e Colfiorito - Risorse idriche, inquinamento delle acque, rischio idraulico",
        location: "(Regione Umbria)",
      },
      {
        path1: "territorio/piani-territoriali-urbanistici/Vara.jpg",
        path2: "territorio/piani-territoriali-urbanistici/Vara2.jpg",
        description: "Piano di messa in sicurezza idraulica e recupero conservativo<br> di ambienti fluviali e alluvionati",
        location: "(Autorità di Bacino Interregionale del Magra - Fiume Vara - SP)",
      },
    ],
  },
  {
    id: "mitigazione-ambientale",
    category: "Territorio",
    title: "Mitigazione ambientale",
    imgs: [
      {
        path1: "territorio/mitigazione-ambientale/Aosta.jpg",
        path2: "territorio/mitigazione-ambientale/Aosta2.jpg",
        description: "Progetti per il riassetto idrogeologico di bacini montani",
        location: "(Regione Valle d’Aosta)",
      },
      {
        path1: "territorio/mitigazione-ambientale/Anghiari.jpg",
        path2: "territorio/mitigazione-ambientale/Anghiari2.jpg",
        description: "Riqualificazione di un bacino idrico derivante da estrazione di inerti in zona riparia",
        location: "(Comune di Anghiari - Fiume Tevere - AR)",
      },
      {
        path1: "territorio/mitigazione-ambientale/Calenzano.jpg",
        path2: "territorio/mitigazione-ambientale/Calenzano2.jpg",
        description: "Casse di espansione: interventi di mitigazione ambientale<br> e creazione di parco fluviale",
        location: "(Comune di Calenzano - Torrente Marina - FI)",
      },
      {
        path1: "territorio/mitigazione-ambientale/Ecodotto.jpg",
        path2: "territorio/mitigazione-ambientale/Ecodotto2.jpg",
        description: "Linee guida per la permeabilità delle infrastrutture stradali<br> nei confronti della fauna",
        location: "(APAT - Agenzia nazionale Protezione Ambiente e Servizi Tecnici)",
      },
      {
        path1: "territorio/mitigazione-ambientale/ValTiberina.jpg",
        path2: "territorio/mitigazione-ambientale/ValTiberina2.jpg",
        description: "Progetti per la sistemazione e il ripristino ambientale di cave",
        location: "(aziende varie - Valtiberina Toscana - AR)",
      },
    ],
  },
  // Ecoturismo
  {
    id: "sentieristica-cammini",
    category: "Ecoturismo",
    title: "Sentieristica e cammini",
    imgs: [
      {
        path1: "ecoturismo/sentieristica-cammini/SanFrancesco.jpg",
        path2: "ecoturismo/sentieristica-cammini/SanFrancesco2.jpg",
        description:
          "Progetto Cammino di San Francesco (tratti in toscana),<br> designato dal MIBACT di interesse nazionale, ~ 200 km",
        location: "(9 Comuni in Provincia di Arezzo)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/Etrusca.jpg",
        path2: "ecoturismo/sentieristica-cammini/Etrusca2.jpg",
        description: "Via Etrusca Volterra-Piombino, progetto Eccellenze in Etruria,<br> Regione Toscana, ~ 150 km",
        location: "(11 Comuni, Province Pisa, Livorno, Grosseto)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/ValCecina.jpg",
        path2: "ecoturismo/sentieristica-cammini/ValCecina2.jpg",
        description: "Nuova rete escursionistica dell’Alta Val di Cecina,<br> 40 percorsi a piedi, cavallo, MTB, ~ 500 km",
        location: "(Unione Comuni Montani AVC, provincia di Pisa)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/CittàEtrusche.jpg",
        path2: "ecoturismo/sentieristica-cammini/CittàEtrusche2.jpg",
        description: "Via delle Città Etrusche e sentieristica principale Colline Metallifere, ~ 400 km",
        location: "(Parco Nazionale Colline Metallifere Grossetane)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/ViaClodia.jpg",
        path2: "ecoturismo/sentieristica-cammini/ViaClodia2.jpg",
        description: "Progetto Via Clodia Sorano-Roselle",
        location: "(Comuni Scansano, Manciano, Grosseto)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/Sentieristica_Sicilia.jpg",
        path2: "ecoturismo/sentieristica-cammini/Sentieristica_Sicilia2.jpg",
        description: "Progetto Reti di Lana, percorsi di ecoturismo e mobilità dolce integrata<br> nelle aree a pastorizia",
        location: "(Fondazione per il Sud e Lavoro - Sicilia)",
        link: "/doc/SCHEDA DESCRIZIONE PROGETTO SENTIERI - MAURIZIO.BACCI_SICILIA.pdf",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/Capraia.jpg",
        path2: "ecoturismo/sentieristica-cammini/Capraia2.jpg",
        description: "Riqualificazione sentieristica - Interreg II Toscana-Corsica:<br> interventi per la fruizione sostenibile",
        location: "(Ente Parco Nazionale Arcipelago Toscano - Isola di Capraia, LI)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/Casternovo.jpg",
        path2: "ecoturismo/sentieristica-cammini/Casternovo2.jpg",
        description: "Progetto per riqualificazione rete sentieristica<br> del territorio comunale a piedi e MTB",
        location: "(Comune di Castelnuovo di Val di Cecina - PI)",
      },
      {
        path1: "ecoturismo/sentieristica-cammini/Accesa.jpg",
        path2: "ecoturismo/sentieristica-cammini/Accesa2.jpg",
        description: "Percorsi di accesso e fruizione di area umida e lacustre",
        location: "(Comune di Massa Marittima - Lago dell’Accesa - GR)",
      },
    ],
  },
  {
    id: "piste-ciclabili",
    category: "Ecoturismo",
    title: "Piste ciclabili",
    imgs: [
      {
        path1: "ecoturismo/piste-ciclabili/Empoli.jpg",
        path2: "ecoturismo/piste-ciclabili/Empoli2.jpg",
        description: "Pista ciclabile lungo il F. Arno dalle periferie al centro",
        location: "(Comune di Empoli - FI)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/Pontassieve.jpg",
        path2: "ecoturismo/piste-ciclabili/Pontassieve2.jpg",
        description: "Pista ciclabile lungo il corso del F. Arno presso Le Sieci",
        location: "(Comune di Pontassieve - FI)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/Abruzzo-disabili.jpg",
        path2: "ecoturismo/piste-ciclabili/Abruzzo-disabili2.jpg",
        description: "Percorsi ciclo-pedonali e tematici presso il Centro Visite di Val Fondillo",
        location: "(Ente Parco Nazionale d’Abruzzo - AQ)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/Bucine.jpg",
        path2: "ecoturismo/piste-ciclabili/Bucine2.jpg",
        description: "Percorsi ciclo-pedonali e tematici lungo il T. Ambra presso Levane",
        location: "(Comune Bucine - AR)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/FiglinePista.jpg",
        path2: "ecoturismo/piste-ciclabili/FiglinePista2.jpg",
        description: "Pista ciclabile lungo il Fiume Arno e il Torrente Cesto",
        location: "(Comune di Figline Valdarno - FI)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/MTB_Castelnuovo.jpg",
        path2: "ecoturismo/piste-ciclabili/MTB_Castelnuovo2.jpg",
        description: "Progetto per realizzazione percorsi MTB downhill",
        location: "(Comune di Castelnuovo di Val di Cecina - PI)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/Portopino.jpg",
        path2: "ecoturismo/piste-ciclabili/Portopino2.jpg",
        description: "Pista ciclabile “Le vie del sale, Stagno di Porto Botte e Promontorio,<br> dune e zona umida di Portopino”",
        location: "(Unioni Comuni del Sulcis - CI)",
      },
      {
        path1: "ecoturismo/piste-ciclabili/Pratomagno.jpg",
        path2: "ecoturismo/piste-ciclabili/Pratomagno2.jpg",
        description: "Riqualificazione di strada forestale rientrante nei percorsi pedo-ciclabili CAI",
        location: "(Unione Comuni Montani Pratomagno -<br> Loc. Roveraia nel Comune di Loro Ciuffenna - AR)",
      },
    ],
  },
  {
    id: "fruizione-naturalistica",
    category: "Ecoturismo",
    title: "Fruizione naturalistica",
    imgs: [
      {
        path1: "ecoturismo/fruizione-naturalistica/Fruizione_Capraia.jpg",
        path2: "ecoturismo/fruizione-naturalistica/Fruizione_Capraia2.jpg",
        description:
          "Fruizione e salvaguardia aree marine e terrestri Isola di Capraia:<br> itinerari di seawatching e sentieristica",
        location: "(Isola di Capraia - LI)",
      },
      {
        path1: "ecoturismo/fruizione-naturalistica/fruizione-calenzano.jpg",
        path2: "ecoturismo/fruizione-naturalistica/fruizione-calenzano2.jpg",
        description: "Progetti Parchi territoriali di Travalle e Carpugnane",
        location: "(Comune di Calenzano - FI)",
      },
      {
        path1: "ecoturismo/fruizione-naturalistica/LagoAccesa.jpg",
        path2: "ecoturismo/fruizione-naturalistica/LagoAccesa2.jpg",
        description: "Riqualificazione ambientale e fruizione sostenibile Lago dell’Accesa",
        location: "(Comune di Massa Marittima - GR)",
      },
      {
        path1: "ecoturismo/fruizione-naturalistica/Ambra.jpg",
        path2: "ecoturismo/fruizione-naturalistica/Ambra2.jpg",
        description: "Parco fluviale: riqualificazione paesaggistica e ambientale<br> con interventi per fruizione",
        location: "(Comune Bucine - T. Ambra presso Levane - AR)",
      },
      {
        path1: "ecoturismo/fruizione-naturalistica/ArnoPontassieve.jpg",
        path2: "ecoturismo/fruizione-naturalistica/ArnoPontassieve2.jpg",
        description:
          "Percorsi ciclo-pedonali, aree sosta e gioco, accessi attrezzati al fiume, pontili per canoe, vegetazione, ingegneria naturalistica, didattica",
        location: "Comune di Pontassieve - Fiume Arno presso Le Sieci - FI)",
      },
      {
        path1: "ecoturismo/fruizione-naturalistica/Serchio.jpg",
        path2: "ecoturismo/fruizione-naturalistica/Serchio2.jpg",
        description: "Riqualificazione idraulico-ambientale e fruizione: pista ciclabile,<br> percorsi tematici lungo argine",
        location: "(Comune di S. Giuliano Terme - Fiume Serchio - PI)",
      },
    ],
  },
  {
    id: "turismo-in-treno",
    category: "Ecoturismo",
    title: "Turismo in treno",
    imgs: [
      {
        path1: "ecoturismo/turismo-in-treno/sirente-velino.jpg",
        path2: "ecoturismo/turismo-in-treno/sirente-velino2.jpg",
        description: "Promozione turismo in treno - Progetto co-marketing Slow Park",
        location: "(Ente Parco Regionale Sirente Velino - AQ)",
      },
      {
        path1: "ecoturismo/turismo-in-treno/brenta.jpg",
        path2: "ecoturismo/turismo-in-treno/brenta2.jpg",
        description: "La trenovia del Brenta",
        location: "Comunità locali - Valstagna, VI",
      },
      {
        path1: "ecoturismo/turismo-in-treno/airone.jpg",
        path2: "ecoturismo/turismo-in-treno/airone2.jpg",
        description: 'Reportage: “La natura dal finestrino: treni d’Europa"',
        location: "(Rivista Airone di Agosto 2003 - Giorgio Mondadori editore)",
      },
      {
        path1: "ecoturismo/turismo-in-treno/terranuova.jpg",
        path2: "ecoturismo/turismo-in-treno/terranuova2.jpg",
        description: "Articoli bimestrali sui temi: turismo sostenibile, viaggi in treno, trekking, bici",
        location: "(Rivista AAM Terranuova)",
      },
      {
        path1: "ecoturismo/turismo-in-treno/Toscanafotofestival.jpg",
        path2: "ecoturismo/turismo-in-treno/Toscanafotofestival2.jpg",
        description:
          'Toscanafotofestival, manifestazione internazionale di fotografia -<br> mostre fotografiche sul tema "Treni & Paesaggi"',
        location: "(Consorzio Turistico AMTUR - Massa Marittina - GR)",
      },
      {
        path1: "ecoturismo/turismo-in-treno/Turismointreno.jpg",
        path2: "ecoturismo/turismo-in-treno/Turismointreno2.jpg",
        description: "Turismointreno: eventi vari",
        location: "(Convegni, mostre, siti web)",
      },
    ],
  },
  {
    id: "marketing-ecoturistico",
    category: "Ecoturismo",
    title: "Marketing ecoturistico",
    imgs: [
      {
        path1: "ecoturismo/marketing-ecoturistico/Marketing_Sicilia.jpg",
        path2: "ecoturismo/marketing-ecoturistico/Marketing_Sicilia2.jpg",
        description: "Reti di Lana: progetto di ecoturismo e sviluppo rurale sostenibile",
        location: "(Lavoro e non solo - Regione Sicilia)",
      },
      {
        path1: "ecoturismo/marketing-ecoturistico/Trieste.jpg",
        path2: "ecoturismo/marketing-ecoturistico/Trieste2.jpg",
        description: "Sistema degli itinerari turistici del territorio:<br> interventi per informazione, gestione e segnaletica",
        location: "(Provincia di Trieste)",
      },
      {
        path1: "ecoturismo/marketing-ecoturistico/Anapo.jpg",
        path2: "ecoturismo/marketing-ecoturistico/Anapo2.jpg",
        description: "Parco Letterario Salvatore Quasimodo",
        location: "(Fondazione Nievo - Riserva Regionale Valle dell’Anapo, SR)",
      },
      {
        path1: "ecoturismo/marketing-ecoturistico/Montecastelli.jpg",
        path2: "ecoturismo/marketing-ecoturistico/Montecastelli2.jpg",
        description: "Riqualificazione e recupero ambientale area archeo-mineraria Val Pavone",
        location: "(Comuni di Pomarance e Castelnuovo VC, PI)",
      },
      {
        path1: "ecoturismo/marketing-ecoturistico/ChiantiSenese.jpg",
        path2: "ecoturismo/marketing-ecoturistico/ChiantiSenese2.jpg",
        description: "Progetto del Parco territoriale e ambientale<br> dell’alta valle del Fiume Arbia",
        location: "(Comune di Gaiole capofila - Chianti Senese, SI)",
        link: "/doc/SCHEDA DESCRIZIONE PROGETTO SENTIERI - MAURIZIO.BACCI_TOSCANA_def_CHIANTISENESE.pdf",
      },
      {
        path1: "ecoturismo/marketing-ecoturistico/Vivimed.jpg",
        path2: "ecoturismo/marketing-ecoturistico/Vivimed2.jpg",
        description: "Progetto Interreg Marittimo Italia Francia VIVIMED -<br> Seminari Ecoturismo aree interne",
        location: "(Simurg Ricerche srl - Toscana)",
      },
    ],
  },
  // Energia
  {
    id: "efficienza-energetica",
    category: "Energia",
    title: "Efficienza energetica",
    imgs: [
      {
        path1: "energia/efficienza-energetica/CEI.jpg",
        path2: "energia/efficienza-energetica/CEI2.jpg",
        description: "Impianto di cogenerazione per la sede del Comitato Elettrotecnico Italiano",
        location: "(Esco Italia srl - Milano)",
      },

      {
        path1: "energia/efficienza-energetica/CNR.jpg",
        path2: "energia/efficienza-energetica/CNR2.jpg",
        description: "Servizio gestione energetica: analisi consumi,<br> censimento utenze, controllo interventi",
        location: "(CNR Firenze - Polo di Sesto Fiorentino)",
      },
      {
        path1: "energia/efficienza-energetica/Selene.jpg",
        path2: "energia/efficienza-energetica/Selene2.jpg",
        description:
          "Progetto di misura e procedura titoli efficienza energetica stabilimento<br> Selene SpA, azienda leader nei film plastici",
        location: "(Esco Veneto srl - Lucca)",
      },
      {
        path1: "energia/efficienza-energetica/VillaSerristori.jpg",
        path2: "energia/efficienza-energetica/VillaSerristori2.jpg",
        description:
          "Piano di Recupero complesso Villa Serristori:<br> ristrutturazione, ricostruzione, riqualificazione paesaggistica",
        location: "(Villa Donoratico srl - LI)",
      },
      {
        path1: "energia/efficienza-energetica/Ustica.jpg",
        path2: "energia/efficienza-energetica/Ustica2.jpg",
        description:
          "Ministero dell’Ambiente: programma “Fonti rinnovabili, risparmio energetico e mobilità sostenibile nelle isole minori”:<br> Progetto sostituzione infissi Casa Comunale",
        location: "(Comune di Ustica - PA)",
      },
      {
        path1: "energia/efficienza-energetica/bici.jpg",
        path2: "energia/efficienza-energetica/bici2.jpg",
        description: "Il veicolo minimo: collaborazione a Progetto LIFE Ambiente,<br> innovazione mobilità sostenibile integrata",
        location: "(Tecnologie Urbane srl - Milano)",
      },
    ],
  },
  {
    id: "fonti-rinnovabili",
    category: "Energia",
    title: "Fonti rinnovabili",
    imgs: [
      {
        path1: "energia/fonti-rinnovabili/gorgona.jpg",
        path2: "energia/fonti-rinnovabili/gorgona2.jpg",
        description:
          "Programma sostenibilità ambientale isole minori, Ministero Ambiente: Progetto Risparmio energetico e fonti rinnovabili Isola di Gorgona",
        location: "(Comune di Livorno)",
      },
      {
        path1: "energia/fonti-rinnovabili/Idro.jpg",
        path2: "energia/fonti-rinnovabili/Idro2.jpg",
        description: "Studio di fattibilità impianti idroelettrici F. Arno e affluenti principali",
        location: "(Provincia Arezzo)",
      },
      {
        path1: "energia/fonti-rinnovabili/wwf-italia.jpg",
        path2: "energia/fonti-rinnovabili/wwf-italia2.jpg",
        description: "Impianto Fotovoltaico",
        location: "(Esco Italia - sede nazionale WWF Italia - Roma)",
      },
      {
        path1: "energia/fonti-rinnovabili/SanVincenzo.jpg",
        path2: "energia/fonti-rinnovabili/SanVincenzo2.jpg",
        description: "Rendering e modelli 3D - Fotosimulazione pale eoliche",
        location: "(Comune di San Vincenzo - LI)",
      },
      {
        path1: "energia/fonti-rinnovabili/pianosa.jpg",
        path2: "energia/fonti-rinnovabili/pianosa2.jpg",
        description:
          "Filiera biomasse locali provenienti da riqualificazione ambientale<br> e loro utilizzo energetico + solare termico",
        location: "(Ministero Politiche Agricole - Isola di Pianosa, GR)",
      },
      {
        path1: "energia/fonti-rinnovabili/eolico.jpg",
        path2: "energia/fonti-rinnovabili/eolico2.jpg",
        description: "Impianti eolici per siti isolati (rifugi)",
        location: "(Comunità Montana Valtiberina Toscana - Alpe della Luna, AR)",
      },

      {
        path1: "energia/fonti-rinnovabili/oasi-wwf.jpg",
        path2: "energia/fonti-rinnovabili/oasi-wwf2.jpg",
        description:
          "Progetti autoproduzione solare termico e fotovoltaico presso centri<br> di educazione ambientale e di visita oasi naturalistiche",
        location: "(WWF Italia - varie regioni)",
      },
      {
        path1: "energia/fonti-rinnovabili/Brambilla.jpg",
        path2: "energia/fonti-rinnovabili/Brambilla2.jpg",
        description: "Corte Nuova: edifici riqualificati in bioedilizia, impianti e tecnologie ecologiche",
        location: "(Roberto Brambilla - Concorezzo, MI)",
      },
    ],
  },
  {
    id: "bioedilizia-bioclimatica",
    category: "Energia",
    title: "Bioedilizia bioclimatica",
    imgs: [
      {
        path1: "energia/bioedilizia-bioclimatica/scuola.jpg",
        path2: "energia/bioedilizia-bioclimatica/scuola2.jpg",
        description:
          "Refettorio scolastico e sala conferenze<br> (bioclimatica, geotermia, terra cruda, legno, materiali naturali)",
        location: "(Comune Vezzano Ligure - SP)",
        link: "/doc/Articolo_Impianti-bioedilizia_IRIS_Bio-struttura scolastica a Vezzano Ligure.pdf",
      },
      {
        path1: "energia/bioedilizia-bioclimatica/bottagone.jpg",
        path2: "energia/bioedilizia-bioclimatica/bottagone2.jpg",
        description: "Centro visite e osservatorio faunistico<br> Riserva naturale regionale Orti del Bottagone",
        location: "(WWF Toscana - Piombino, LI)",
      },
      {
        path1: "energia/bioedilizia-bioclimatica/guccini.jpg",
        path2: "energia/bioedilizia-bioclimatica/guccini2.jpg",
        description: "Ristrutturazione di un antico edificio in ambiente rurale",
        location: "(Casa Guccini - Pavana, PT)",
      },
      {
        path1: "energia/bioedilizia-bioclimatica/Bologna.jpg",
        path2: "energia/bioedilizia-bioclimatica/Bologna2.jpg",
        description: 'Cantiere didattico di "bio-ristrutturazione”',
        location: "(COFIMP - Bologna)",
      },
      {
        path1: "energia/bioedilizia-bioclimatica/CasaEcologica.jpg",
        path2: "energia/bioedilizia-bioclimatica/CasaEcologica2.jpg",
        description:
          "La casa ecologica: vari progetti di ristrutturazioni a basso impatto ambientale,<br> alta salubrità, ottimizzazione ciclo risorse",
        location: "(Abruzzo, Lombardia, Piemonte, Sicilia, Toscana)",
        link: "/doc/brochure_la_casa_ecologica_compressed.pdf",
      },
      {
        path1: "energia/bioedilizia-bioclimatica/reti-lana.jpg",
        path2: "energia/bioedilizia-bioclimatica/reti-lana2.jpg",
        description: "Cantiere pilota per isolamento temico tramite lana di scarto -<br> Progetto Reti di Lana",
        location: "(Fondazione per il Sud - Montelepre, PA)",
      },
    ],
  },
  // Formazione
  {
    id: "formazione-universitaria",
    category: "Formazione",
    title: "Formazione universitaria e post",
    imgs: [
      {
        path1: "formazione/formazione-universitaria/CorsoBioarchetetturaPratoIngegneria.jpg",
        description: "Corso Bioarchetettura - Impatto Ambientale",
        location: "(Università di Prato, Ingegneria)",
      },
      {
        path1: "formazione/formazione-universitaria/CorsoIngegneriaNaturalisticaFirenzeScienzeAgrarie.jpg",
        description: "Corso Ingegneria Naturalistica",
        location: "(Università di Firenze, Scienze Agrarie e Forestali)",
      },
      {
        path1: "formazione/formazione-universitaria/CorsoVegetazioneFluvialeFirenze.jpg",
        description: "Corso Vegetazione Fluviale",
        location: "(Università di Firenze, Scienze Forestali)",
      },
      {
        path1: "formazione/formazione-universitaria/CorsoPrincipiRiqualificazioneFluvialeSavona.jpg",
        description: "Corso, Principi Riqualificazione Fluviale",
        location: "(Università Genova, Ingegneria sede Savona)",
      },
      {
        path1: "formazione/formazione-universitaria/ForumInternazionaleMareCostaForteMarmi.jpg",
        description: "Forum Internazionale Mare-Costa - Riqualificazione Dunale",
        location: "(Forte dei Marmi, LU)",
      },
      {
        path1: "formazione/formazione-universitaria/DottoratoPolitecnicoMilano.jpg",
        description: "Laboratorio Progetto Urbano e del Paesaggio - Ambienti idrici e paesaggi urbani",
        location: "(Dottorato Politecnico di Milano)",
      },
      {
        path1: "formazione/formazione-universitaria/MasterArchitetturaSostenibileFirenzeArchitettura.jpg",
        description: "Master Architettura sostenibile - Riqualificazione versanti costieri",
        location: "(Università di Firenze, Architettura)",
      },
      {
        path1: "formazione/formazione-universitaria/MasterRestauraPaesaggioTuscia.jpg",
        description: "Master Restaura Paesaggio ecosistemi costieri - Riqualificazione fluviale",
        location: "(Università della Tuscia, Viterbo)",
      },
      {
        path1: "formazione/formazione-universitaria/MasterRischioIdraulicoFirenze.jpg",
        description: "Master Rischio Idraulico - Ingegneria Naturalistica",
        location: "(Università di Firenze, Ingegneria)",
      },
      {
        path1: "formazione/formazione-universitaria/MasterWaterFrontFirenze.jpg",
        description: "Master Water Front Mediterranei",
        location: "(Università di Firenze, Architettura)",
      },
      {
        path1: "formazione/formazione-universitaria/CorsoPaesaggioUNIFILettere.jpg",
        description: "Corso sul Paesaggio",
        location: "(Università di Firenze - Fac. Lettere)",
      },
    ],
  },
  {
    id: "professionisti-ditte",
    category: "Formazione",
    title: "Professionisti e ditte",
    imgs: [
      {
        path1: "formazione/professionisti-ditte/CantiereDidatticoBioediliziaBologna.jpg",
        path2: "formazione/professionisti-ditte/CantiereDidatticoBioediliziaBologna2.jpg",
        description: "Cantiere didattico bioedilizia",
        location: "(Regione Emilia Romagna - Bologna)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoEdiliziaOrdineIngegneriFirenze.jpg",
        path2: "formazione/professionisti-ditte/CorsoEdiliziaOrdineIngegneriFirenze2.jpg",
        description: "Corso materiali naturali in edilizia",
        location: "(Ordine Ingegneri Provincia Firenze)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoImpattoAmbientaleFirenze.jpg",
        path2: "formazione/professionisti-ditte/CorsoImpattoAmbientaleFirenze2.jpg",
        description: "Corso Valutazione Impatto Ambientale",
        location: "(Ordine Ingegneri Provincia Firenze)",
      },
      {
        path1: "formazione/professionisti-ditte/LaboratorioOrdineNazionaleBiologi.jpg",
        path2: "formazione/professionisti-ditte/LaboratorioOrdineNazionaleBiologi2.jpg",
        description: "Laboratorio progetti urbani e paesaggio",
        location: "(Ordine Nazionale Biologi)",
      },
      {
        path1: "formazione/professionisti-ditte/CantiereDidatticoJesi.jpg",
        description: "Cantiere didattico Ingegneria Naturalistica",
        location: "(Comune di Jesi, Fosso Acquaticcio)",
      },
      {
        path1: "formazione/professionisti-ditte/CantiereDidatticoElba.jpg",
        description: "Cantiere didattico Ingegneria Naturalistica",
        location: "(Parco Nazionale Arcipelago Toscano - Isola d'Elba)",
      },
      {
        path1: "formazione/professionisti-ditte/ConvegnoAIPINPisa.jpg",
        description: "Convegno Ingegneria Naturalistica",
        location: "(AIPIN, Pisa)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoIngegneriaNaturalisticaPistoia.jpg",
        description: "Corso Ingegneria Naturalistica",
        location: "(Ordine Architetti, Pistoia)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoEntiPubbliciIngegneriaNaturalistica.jpg",
        description: "Corsi per tecnici enti pubblici su Ingegneria Naturalistica",
        location: "(Toscana Loc. Varie)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoRiqualificazioneCanaliMilano.jpg",
        description: "Corso Riqualificazione canali",
        location: "(Provincia di Milano, Milano)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoRiqualificazioneAcquaMilano.jpg",
        description: "Corso riqualificazione corsi d'acqua e ingegneria naturalistica",
        location: "(Provincia di Milano)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoRoadEcologyLipuFirenze.jpg",
        description: "Corso Road Ecology",
        location: "(Lipu, Firenze)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoSicurezzaAppaltiPubbliciValdera.jpg",
        description: "Corso Sicurezza Appalti Pubblici - Ingegneria naturalistica",
        location: "(Consorzio Bonifica Valdera)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoFitodepurazioneOrdineIngegneriFirenze.jpg",
        description: "Corso Fitodepurazione",
        location: "(Ordine Ingegneri Provincia Firenze)",
      },
      {
        path1: "formazione/professionisti-ditte/CorsoVegetazioneFluvialeOmbrone.jpg",
        description: "Corso Vegetazione Fluviale",
        location: "(CIRF - Fiume Ombrone, Orbetello - GR)",
      },
    ],
  },
  {
    id: "informazione-pubblica",
    category: "Formazione",
    title: "Informazione pubblica",
    imgs: [
      {
        path1: "formazione/informazione-pubblica/ParcoMontemarcelloMagraVaraArcola.jpg",
        path2: "formazione/informazione-pubblica/ParcoMontemarcelloMagraVaraArcola2.jpg",
        description: "Processo partecipato Idee & Azioni",
        location: "(Parco Montemarcello Magra Vara, Arcola - SP)",
        link: "/doc/locandina ideeazioni_seminario_arcola.pdf",
      },
      {
        path1: "formazione/informazione-pubblica/BoscoPulitoProvinciaFirenze.jpg",
        description: "Bosco Pulito",
        location: "(Cittadini, Provincia di Firenze)",
      },
      {
        path1: "formazione/informazione-pubblica/ConferenzaEnergiaAmbienteVittorioVeneto.jpg",
        description: "Conferenza, Energia Ambiente & Stili di Vita",
        location: "(Vittorio Veneto)",
      },
      {
        path1: "formazione/informazione-pubblica/ConferenzaRiqualificazioneFluvialeArrone.jpg",
        description: "Conferenza Riqualificazione Fluviale",
        location: "(Associazione canoisti Terni - Arrone)",
      },
      {
        path1: "formazione/informazione-pubblica/ConferenzaRisparmioAcquaSciarada.jpg",
        description: "Conferenza, Risparmio Acqua",
        location: "(Ass. Sciarada, San Vincenzo - LI)",
      },
      {
        path1: "formazione/informazione-pubblica/ConvegnoVivimedPontremoli.jpg",
        description: "Convegno Vivimed, Treni & Turismo",
        location: "(Pontremoli - MS)",
      },
      {
        path1: "formazione/informazione-pubblica/SeminarioCasaEcologicaCollesalvetti.jpg",
        description: "Seminario, La Casa ecologica e Stili di vita sostenibili",
        location: "(Bilanci di Giustizia, Collesalvetti - LI)",
      },
      {
        path1: "formazione/informazione-pubblica/ConvegnoCostambienteLivorno.jpg",
        description: "Convegno Costambiente - Dune & Seawatching",
        location: "(Comune Livorno)",
      },
      {
        path1: "formazione/informazione-pubblica/CorsoItaliaNostraMI.jpg",
        description: "Corso su ambiente e territorio",
        location: "(Italia Nostra - Milano)",
      },
    ],
  },
  {
    id: "didattica-scuole",
    category: "Formazione",
    title: "Didattica scuole",
    imgs: [
      {
        path1: "formazione/didattica-scuole/ScuoleSanCasciano.jpg",
        path2: "formazione/didattica-scuole/ScuoleSanCasciano2.jpg",
        description: "Escursione didattica",
        location: "(Scuole, San Casciano - FI)",
      },
      {
        path1: "formazione/didattica-scuole/ProgettoPaesaggioFluvialeRegioneToscana.jpg",
        path2: "formazione/didattica-scuole/ProgettoPaesaggioFluvialeRegioneToscana2.jpg",
        description: "Progetto Paesaggio Fluviale",
        location: "(Scuole - Regione Toscana)",
        link: "/doc/Brochure_Paesaggio_Fluviale_SCUOLE.pdf",
      },
      {
        path1: "formazione/didattica-scuole/FirenzeRaftingFiumePesa.jpg",
        path2: "formazione/didattica-scuole/FirenzeRaftingFiumePesa2.jpg",
        description: "Campus didattico-emozionale",
        location: "(Firenze Rafting - Fiume Pesa)",
      },
      {
        path1: "formazione/didattica-scuole/BicinottataFiumePesa.jpg",
        description: "Bicinottata",
        location: "Fiume Pesa, San Casciano - FI",
      },
      {
        path1: "formazione/didattica-scuole/CampusFluvialeCIRFFiumeBrenta.jpg",
        description: "Campus fluviale CIRF",
        location: "(Fiume Brenta, Valstagna - VI)",
      },
      {
        path1: "formazione/didattica-scuole/DiscesaCanoististicaFiumeNera.jpg",
        description: "Discesa canoististica del fiume Nera",
        location: "(Fiume Nera - TR)",
      },
      {
        path1: "formazione/didattica-scuole/EscursioniDidatticheScuoleMaterne.jpg",
        description: "Escursioni Didattiche",
        location: "(Scuole Materne, Cerbaia Val di Pesa - FI)",
      },
      {
        path1: "formazione/didattica-scuole/RiservaBandellaValleInferno.jpg",
        description: "Escursioni educazione ambientale",
        location: "(Riserva Bandella Valle dell'Inferno - AR)",
      },
      {
        path1: "formazione/didattica-scuole/FestivalNazionaleAcqua.jpg",
        description: "Festival Nazionale dell'Acqua",
        location: "(Lezioni scuole superiori - L'Aquila)",
      },
      {
        path1: "formazione/didattica-scuole/InterpretazionePaesaggioSanCasciano.jpg",
        description: "Interpretazione Paesaggio",
        location: "(Scuole - San Casciano - FI)",
      },
      {
        path1: "formazione/didattica-scuole/VivereIlFiumeRegioneToscana.jpg",
        description: "Vivere il Fiume",
        location: "(Scuole Elementari - Regione Toscana)",
      },
    ],
  },

  {
    id: "pubblicazioni",
    category: "Formazione",
    title: "Pubblicazioni",
    imgs: [
      {
        path1: "formazione/pubblicazioni/1-arno.jpg",
        description: "Inquinamento fluviale: realizzazione e uso di modelli matematici: Uno studio di caso sull’Arno",
        location: "(Regione Toscana - Marsilio editore, Venezia)",
      },
      {
        path1: "formazione/pubblicazioni/2-impatto-ambientale.jpg",
        description: "Dalla valutazione di impatto ambientale alla valutazione integrata partecipativa",
        location: "(Provincia di Siena - Cantagalli ed., Siena)",
      },
      {
        path1: "formazione/pubblicazioni/3-linee-guida.jpg",
        description: "Linee guida per interventi di ingegneria naturalistica lungo i corsi d’acqua",
        location: "(Regione Marche)",
      },
      {
        path1: "formazione/pubblicazioni/4-ecoconsigli.jpg",
        description: "Schede ECOCONSIGLI: acqua, energia, rifiuti, trasporti",
        location: "(WWF Italia)",
      },
      {
        path1: "formazione/pubblicazioni/5-eco-weekend.jpg",
        description: "Articoli in Ecoweekend, speciale Rivista Airone di aprile 03",
        location: "(Giorgio Mondadori editore)",
      },
      {
        path1: "formazione/pubblicazioni/schede-gallura.jpg",
        path2: "formazione/pubblicazioni/schede-gallura2.jpg",
        description: "Schede tecnico-illustrative sull'energia: efficienza energetica e fonti rinnovabili",
        location: "(Esco Italia - Unione Comuni Gallura)",
      },
      {
        path1: "formazione/pubblicazioni/VezzanoLigure.jpg",
        description: 'Articolo su "Progettando"',
        location: "(Periodico dell'ordine degli ingegneri - Provincia di Firenze)",
      },
      {
        path1: "formazione/pubblicazioni/ArticoloRivistaScienzeNaturali.jpg",
        description: "Articolo rivista della Società Toscana di Scienze Naturali",
        location: "(Iris - Nemo)",
      },
      {
        path1: "formazione/pubblicazioni/Bacci_IngNatVeraFalsa.jpg",
        description: 'Articolo per rivista "Alberi e Territorio"',
        location: "(Ed. Il Sole 24 Ore)",
      },
      {
        path1: "formazione/pubblicazioni/Bacci_Legislazione_78.jpg",
        description: 'Articolo per rivista "Alberi e Territorio"',
        location: "(Ed. Il Sole 24 Ore)",
      },
      {
        path1: "formazione/pubblicazioni/Bacci_TerraVita1.jpg",
        description: 'Articolo per rivista "Alberi e Territorio"',
        location: "(Ed. Il Sole 24 Ore)",
      },
      {
        path1: "formazione/pubblicazioni/Arsia.jpg",
        description: "Pubblicazione Ente Arsia",
        location: "(Capitolo su alcuni progetti curati dalla Iris)",
      },
      {
        path1: "formazione/pubblicazioni/PianoEnergLivorno.jpg",
        description: "Capitolo su indipendenza energetica Isola di Gorgona",
        location: "(Comune di Livorno)",
      },
      {
        path1: "formazione/pubblicazioni/RegMarche.jpg",
        description: "Manuale Ingegneria Naturalistica",
        location: "(WWF Italia - Regione Marche)",
      },
      {
        path1: "formazione/pubblicazioni/Sorgenia.jpg",
        description: "Report ambientale di Sorgenia s.p.a.",
        location: "",
      },
      {
        path1: "formazione/pubblicazioni/WWF-ReteEco.jpg",
        description: "Pubblicazione su reti ecologiche, capitolo su passaggi per fauna<br/> e mitigazione ambientale",
        location: "(WWF Italia)",
      },
    ],
  },
  {
    id: "fotografia",
    category: "Formazione",
    title: "Fotografia",
    imgs: [
      {
        path1: "formazione/fotografia/1-sinfonia-in-acqua.jpg",
        description: "H2OLTRE: immagini per progetti mutiartistici e didattici incentrati sul tema dell’acqua",
        location: "",
        link: "http://h2oltre.com/",
      },
      {
        path1: "formazione/fotografia/2-treni-paesaggi.jpg",
        description: "Mostre fotografiche (80 foto) sul tema “Treni & Paesaggi”",
        location: "(Toscana Foto Festival e altri)",
      },
      {
        path1: "formazione/fotografia/CorsoFotografiaPercettivaRifugiAppennino.jpg",
        description: "Corso di fotografia percettiva",
        location: "(Rifugi vari, Appennino Tosco-emiliano)",
      },
      {
        path1: "formazione/fotografia/SinfoniaInAcqua.jpg",
        description: "Sinfonia in Acqua - opera multiartistica",
        location: "(Eventi in località varie)",
        link: "http://h2oltre.com/portfolio/sinfonia-in-acqua/",
      },
      {
        path1: "formazione/fotografia/3-inquinamento-arno.jpg",
        description: "Campagna fotografica sulla qualità delle acque dell’Arno",
        location: "(Regione Toscana)",
      },
      {
        path1: "formazione/fotografia/4-sorgenia.jpg",
        description: "Documentazione fotografica del Report ambientale",
        location: "(Sorgenia SpA, varie location)",
      },
      {
        path1: "formazione/fotografia/5-dissesto-idrogeologico.jpg",
        description:
          "Libro ”La riqualificazione fluviale in Italia: linee guida,<br/> strumenti ed esperienze per gestire i corsi d’acqua e il territorio”",
        location: "(CIRF - Mazzanti editore, Venezia)",
      },
      {
        path1: "formazione/fotografia/umbria.jpg",
        description: "Documentazione fotografica per parchi Regione Umbria",
        location: "(Tevere, Trasimeno, Colfiorito e Nera)",
      },
    ],
  },
];
