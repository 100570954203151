import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet-async";
import "../styles/Navbar.css";
import "../styles/Various.css";

const Footer = () => {
  return (
    <footer className="footer mt-auto">
      <Helmet>
        <meta name="description" content="Iris Ambiente Sas - Strategie per l'Ambiente" />
      </Helmet>
      <div className="container text-center my-3">
        <div className="footer-text mb-2">
          <a href="mailto:iris@irisambiente.it" target="_blank" rel="noopener">
            iris@irisambiente.it
          </a>{" "}
          | +39 335 71 23 458
        </div>
        <a
          href="https://www.facebook.com/pages/category/Local-Business/IRIS-Ambiente-380072679828/"
          target="_blank"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faFacebook} className="footer-text social mx-2" />
        </a>
        <a href="https://www.linkedin.com/company/iris-ambiente-sas/about/" target="_blank" rel="noopener">
          <FontAwesomeIcon icon={faLinkedin} className="footer-text social" />
        </a>
        <p className="copyright">© {new Date().getFullYear()} IRIS Ambiente - Webmaster Jacopo Bacci - Foto Maurizio Bacci</p>
      </div>
    </footer>
  );
};

export default Footer;
