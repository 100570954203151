import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Routes from "./Routes";

const App = () => {
  return (
    <>
      <HelmetProvider>
        <Navbar />
        <div className="container mt-5 mb-5 min-vh-100">
          <Routes />
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default App;
