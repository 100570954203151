import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_HOVER_CDN } from "../../utils/constants";
import "../../styles/Various.css";

const RilieviIdro = () => {
  useEffect(() => {
    document.title = "Rilievi idro-ambientali";
  });
  return (
    <div className="RilieviIdro">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta
          name="description"
          content="analisi multicriterio e VIA, studi idrologico-idraulici, analisi qualità acque, rilievi sentieristica, rilievi ambientali speditivi, fotosimulazioni paesaggistiche, dossier video e fotografici, direzione lavori, analisi termografiche ed energetiche, rilievi architettonici"
        />
      </Helmet>
      <h1 className="pb-4">Rilievi idraulici e ambientali</h1>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h4>Applicazioni</h4>
            <ul className="px-4 py-2">
              <li>analisi multicriterio e VIA</li>
              <li>studi idrologico-idraulici</li>
              <li>analisi qualità acque</li>
              <li>rilievi sentieristica</li>
              <li>rilievi ambientali speditivi</li>
              <li>fotosimulazioni paesaggistiche</li>
              <li>dossier video e fotografici</li>
              <li>direzione lavori</li>
              <li>analisi termografiche ed energetiche</li>
              <li>rilievi architettonici</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/idro/applicazioni1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/idro/applicazioni2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row flex-md-row-reverse py-5 align-items-center justify-content-center">
          <div className="col-md-5 offset-md-1">
            <h4>Attrezzatura</h4>
            <ul className="px-4 py-2 attrezzatura-scroller">
              <li>computer e strumentazione informatica connessa</li>
              <li>mulinelli idraulici misuratori di velocità Global Water (adottato dal Servizio Forestale USA)</li>
              <li>kit per misura speditiva indicatori chimico-fisici nelle acque</li>
              <li>
                controller Tablet rugged Geotab 7<br /> con moduli software X-PAD ULTIMATE Android GNSS e GIS
              </li>
              <li>GPS professionali con cartografie tematiche specifiche</li>
              <li>misuratori distanze laser per campo aperto e interni</li>
              <li>misuratore di umidità del suolo</li>
              <li>macchine video-fotografiche reflex professionali</li>
              <li>15 obiettivi fotografici, dal 15 mm al 600 mm</li>
              <li>imbarcazioni pneumatiche</li>
              <li>parco bici di vario genere, di cui 2 a pedalata assistita</li>
              <li>abbigliamento tecnico per cantieri e lavori in ambienti particolari (neve, acqua,…)</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/idro/attrezzatura1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/idro/attrezzatura2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6">
            <blockquote className="blockquote">
              <h4>Software</h4>{" "}
              <footer className="blockquote-footer">Rientra fra i nostri principi l’uso di sofware liberi (open source)</footer>
            </blockquote>

            <ul className="px-4 py-2">
              <li>modellazione idrologica-idraulica (tipo Hec-Ras ed altri)</li>
              <li>diagnosi energetica</li>
              <li>analisi del ciclo di vita di prodotti e processi (LCA)</li>
              <li>disegno tecnico (tipo Autodad)</li>
              <li>gestione dati cartografici e territoriali (GIS)</li>
              <li>elaborazioni grafiche</li>
              <li>calcolo e data base</li>
              <li>scrittura, composizione ed editing testi</li>
              <li>post produzione fotografica</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/rilievi/idro/software1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right border"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/rilievi/idro/software2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RilieviIdro;
