export default [
  {
    id: "acque-e-suolo",
    title: "Acque e suolo",
    subtitle:
      "Ingegneria naturalistica, riqualificazione fluviale, qualità e ciclo acque, fitodepurazione, assetto idrogeologico, ripristino dunale, rinaturazione habitat.",
    imgs: [
      {
        link: "/esperienze/acque-e-suolo/coste-dune",
        path: "acque-e-suolo/coste-dune/Melendugno.jpg",
        name: "Coste e dune",
      },
      {
        link: "/esperienze/acque-e-suolo/erosione-fluviale",
        path: "acque-e-suolo/erosione-fluviale/esino.jpg",
        name: "Erosione fluviale",
      },
      {
        link: "/esperienze/acque-e-suolo/qualita-acque",
        path: "acque-e-suolo/qualita-acque/Publiser.jpg",
        name: "Qualità delle acque",
      },
      {
        link: "/esperienze/acque-e-suolo/zone-umide",
        path: "acque-e-suolo/zone-umide/Accesa.jpg",
        name: "Zone umide",
      },
      {
        link: "/esperienze/acque-e-suolo/versanti",
        path: "acque-e-suolo/versanti/Sardegna.jpg",
        name: "Versanti",
      },
    ],
  },
  {
    id: "territorio",
    title: "Territorio",
    subtitle:
      "Valutazioni impatto ambientale, sociale e incidenza ecologica, mitigazione ambientale e paesaggistica, pianificazione territoriale e di bacino, gestione naturalistica e aree protette.",

    imgs: [
      {
        link: "/esperienze/territorio/vas",
        path: "territorio/vas/ISC.jpg",
        name: "V.A.S.",
      },
      {
        link: "/esperienze/territorio/via",
        path: "territorio/via/Vara.jpg",
        name: "V.I.A.",
      },
      {
        link: "/esperienze/territorio/vinca",
        path: "territorio/vinca/Esino.jpg",
        name: "V.INC.A.",
      },
      {
        link: "/esperienze/territorio/relazioni-paesaggistiche",
        path: "territorio/relazioni-paesaggistiche/Trieste.jpg",
        name: "Relazioni paesaggistiche",
      },
      {
        link: "/esperienze/territorio/piani-territoriali-urbanistici",
        path: "territorio/piani-territoriali-urbanistici/lerici.jpg",
        name: "Piani territoriali e urbanistici",
      },
      {
        link: "/esperienze/territorio/mitigazione-ambientale",
        path: "territorio/mitigazione-ambientale/Aosta.jpg",
        name: "Mitigazione ambientale",
      },
    ],
  },
  {
    id: "ecoturismo",
    title: "Ecoturismo",
    subtitle:
      "Cammini, sentieristica, escursionismo, green ways, piste ciclabili, turismo in treno, mobilità integrata, fruizione, marketing territoriale.",
    imgs: [
      {
        link: "/esperienze/ecoturismo/sentieristica-cammini",
        path: "ecoturismo/sentieristica-cammini/sentieristica-cammini.jpg",
        name: "Sentieristica e cammini",
      },
      {
        link: "/esperienze/ecoturismo/piste-ciclabili",
        path: "ecoturismo/piste-ciclabili/Pontassieve.jpg",
        name: "Piste ciclabili",
      },
      {
        link: "/esperienze/ecoturismo/fruizione-naturalistica",
        path: "ecoturismo/fruizione-naturalistica/Fruizione_Capraia.jpg",
        name: "Fruizione naturalistica",
      },
      {
        link: "/esperienze/ecoturismo/turismo-in-treno",
        path: "ecoturismo/turismo-in-treno/sirente-velino.jpg",
        name: "Turismo in treno",
      },
      {
        link: "/esperienze/ecoturismo/marketing-ecoturistico",
        path: "ecoturismo/marketing-ecoturistico/Trieste.jpg",
        name: "Marketing ecoturistico",
      },
    ],
  },
  {
    id: "energia",
    title: "Energia",
    subtitle:
      "Bioedilizia e bioclimatica, efficienza e risparmio energetico, energie rinnovabili, materiali naturali e durevoli, salubrità e comfort, innovazione e ricerca.",
    imgs: [
      {
        link: "/esperienze/energia/efficienza-energetica",
        path: "energia/efficienza-energetica/CEI2.jpg",
        name: "Efficienza energetica",
      },
      {
        link: "/esperienze/energia/fonti-rinnovabili",
        path: "energia/fonti-rinnovabili/eolico.jpg",
        name: "Fonti rinnovabili",
      },
      {
        link: "/esperienze/energia/bioedilizia-bioclimatica",
        path: "energia/bioedilizia-bioclimatica/Bologna.jpg",
        name: "Bioedilizia bioclimatica",
      },
    ],
  },
  {
    id: "formazione",
    title: "Formazione",
    subtitle:
      "Corsi e seminari di formazione e specializzazione, educazione ambientale, campus in ambienti naturali, divulgazione e pubblicistica, arte e scienza, fotografia percettiva.",
    imgs: [
      {
        link: "/esperienze/formazione/formazione-universitaria",
        path: "formazione/formazione-universitaria/MasterRischioIdraulicoFirenze.jpg",
        name: "Formazione universitaria e post",
      },
      {
        link: "/esperienze/formazione/professionisti-ditte",
        path: "formazione/professionisti-ditte/CorsoEdiliziaOrdineIngegneriFirenze.jpg",
        name: "Professionisti e ditte",
      },
      {
        link: "/esperienze/formazione/informazione-pubblica",
        path: "formazione/informazione-pubblica/ConferenzaEnergiaAmbienteVittorioVeneto.jpg",
        name: "Informazione pubblica",
      },
      {
        link: "/esperienze/formazione/didattica-scuole",
        path: "formazione/didattica-scuole/DiscesaCanoististicaFiumeNera.jpg",
        name: "Didattica scuole",
      },
      {
        link: "/esperienze/formazione/pubblicazioni",
        path: "formazione/pubblicazioni/Bacci_TerraVita1.jpg",
        name: "Pubblicazioni",
      },
      {
        link: "/esperienze/formazione/fotografia",
        path: "formazione/fotografia/1-sinfonia-in-acqua.jpg",
        name: "Fotografia",
      },
    ],
  },
];
