import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { IMAGE_HOVER_CDN } from "../../utils/constants";

const Studi = () => {
  useEffect(() => {
    document.title = "Studi";
  });
  return (
    <div className="Studi">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta
          name="description"
          content="Studi: di fattibilità, per procedure finanziarie, progetti Life
                interreg, PSR - POR, ministero dell'ambiente, fondazioni, per piani e programmi, linee guida tecniche, su temi specifici e di ricerca"
        />
      </Helmet>
      <h1 className="pb-4">Studi</h1>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6">
            <ul className="px-4 py-2">
              <li>di fattibilità</li>
              <li>per procedure finanziarie</li>
              <li>progetti Life</li>
              <li>interreg</li>
              <li>PSR - POR</li>
              <li>ministero dell'ambiente</li>
              <li>fondazioni</li>
              <li>per piani e programmi</li>
              <li>linee guida tecniche</li>
              <li>su temi specifici e di ricerca</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/studi/1-Umbria_MAUB1856.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/studi/2-Desesile3b.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="row align-items-center flex-md-row-reverse pt-5 pb-5">
          <div className="col-md-5 offset-md-1">
            <h4>Valutazioni</h4>
            <ul className="px-4 py-2">
              <li>Localizzazione opere</li>
              <li>VIA</li>
              <li>VAS</li>
              <li>VINCA (Incidenza Ecologica)</li>
              <li>Paesaggistica</li>
              <li>Analisi multicriterio</li>
              <li>Monitoraggi</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/studi/3-VaraGeomorfo1.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/studi/4-ProvMI5.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="row align-items-center pb-5">
          <div className="col-md-6">
            <h4>Piani di Gestione SIC e aree protette</h4>
            <p className="py-2 pr-md-5">
              L'analisi e la stesura di tali Piani viene tipicamente curata da esperti in scienze naturali, agro-forestali,
              biologia e al più geologia. IRIS introduce un approccio interdisciplinare, allargata a competenze di pianificazione
              urbanistico-territoriale, paesaggistica e, in particolare, di ingegneria ambientale e naturalistica. Queste
              risultano molto importanti, al fine di rispondere a esigenze e normative in materia di rinaturalizzazione,
              risanamento di aree degradate, assetto idrogeologico, gestione idraulica, fruizione sostenibile e in sicurezza.
            </p>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/studi/5-Vernise_DSCN0109.JPG").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right border"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/studi/6-Vernise_P153_ET.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <hr />
      <section className="pt-5">
        <h2>
          Esempio <Link to="/esperienze/ecoturismo/sentieristica-cammini">Sentieristica</Link>
        </h2>
        <div className="pt-5">
          <div>
            <h4>Studio di fattibilità</h4>
            <ul className="px-4 py-2">
              <li>
                incontri con l’amministrazione comunale ed eventuali soggetti da essa coinvolti per definire una prima ipotesi di
                scelta dei percorsi
              </li>
              <li>valutazione di un’ipotesi di percorso per la realizzazione di un cammino tematico principale</li>
              <li>individuazione connessioni con altri cammini di interesse regionale e nazionale, esistenti e potenziali</li>
              <li>sopralluoghi sui percorsi individuati</li>
              <li>
                stesura del <em>Master Plan</em> della rete sentieristica territoriale (analisi screening, cartografia d’insieme,
                schede dei sentieri con relative caratteristiche, connessione con i Cammini e con le reti sentieristiche
                limitrofe, scelte strategiche, opportunità socio-economiche)
              </li>
              <li>presentazione del Master Plan ai soggetti interessati e al pubblico</li>
            </ul>
          </div>
          <div className="pt-3">
            <h4>Avviamento, promozione e gestione interventi</h4>
            <ul className="px-4 py-2">
              <li>
                sito web dedicato con descrizione sentieri, mappe e tracce gps scaricabili, interagente con portali istituzionali
              </li>
              <li>materiale promozionale multilingue e carta turistico-escursionistica</li>
              <li>comunicazione dati e info ai canali istituzionali</li>
              <li>
                animazione, per l'avviamento di iniziative microimprenditoriali, rivolte soprattutto ai giovani, finalizzate alle
                attività di gestione, conduzione, manutenzione, guida turistica, didattica ambientale, info e marketing,
                progettazione, organizzazione eventi, accoglienza (p.e. albergo diffuso), diffusione e commercio di prodotti
                locali, ecc.)
              </li>
              <li>formazione guide turistico-escursionistiche</li>
            </ul>
          </div>
        </div>
        <section>
          <div className="row">
            <div className="col-md-12 pt-5">
              <div className="px-md-0 px-1">
                <img
                  className="img-fluid rounded panoramic"
                  src={require("../../img/servizi/studi/esempio-sentieristica.jpg").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Studi;
