import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../styles/Home.css";

const Home = () => {
  useEffect(() => {
    document.title = "Home";
  });
  return (
    <div className="Home">
      <Helmet>
        <meta
          name="description"
          content="Da 30 anni la società IRIS è impegnata nell'applicazione dell’ingegneria naturalistica e di
                  soluzioni eco-compatibili alla pianificazione e gestione del territorio e delle risorse con approccio
                  interdisciplinare e multicriteri. Ha curato progetti e studi per circa 350 enti pubblici e privati coordinando una rete di oltre 200 studi e società in tutta Italia."
        />
      </Helmet>
      <section>
        <div className="row">
          <div className="col-md-12">
            <div className="px-md-0 px-1">
              <img className="img-fluid rounded panoramic" src={require("../img/home/arcobaleno.jpg").default} alt="Arcobaleno" />
            </div>
          </div>
          <div className="col-12">
            <div className="pt-5 px-md-0 px-1">
              <p className="intro-text">
                Da 30 anni la società <strong>IRIS</strong> è impegnata nell'applicazione dell’ingegneria naturalistica e di
                soluzioni eco-compatibili alla pianificazione e gestione del territorio e delle risorse con approccio
                interdisciplinare e multicriteri. <br />
                Ha curato progetti e studi per circa 350 enti pubblici e privati coordinando una rete di oltre 200 studi e società
                in tutta Italia.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4">
        <div className="row jumbotron bg-gradient-light mx-0">
          <div className="col-md-6">
            <div className="px-md-0 px-1">
              <h4>Settori</h4>
              <ul className="px-4 py-2">
                <li>ingegneria naturalistica</li>
                <li>sentieristica e piste ciclabili</li>
                <li>efficienza consumi: energia, acqua, materiali</li>
                <li>fonti rinnovabili, bioedilizia</li>
                <li>fitodepurazione e ciclo idrico</li>
                <li>ecosystem restoration</li>
                <li>urbanistica e territorio</li>
                <li>idraulica fluviale</li>
                <li>geologia applicata</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="px-md-0 px-1 pt-md-0 pt-3">
              <h4>Servizi</h4>
              <ul className="px-4 py-2">
                <li>progettazione e direzione lavori</li>
                <li>studi: fattibilità, Via, Vas, Vinca, paesaggistica</li>
                <li>studi geologici</li>
                <li>grafica, rendering e fotosimulazioni</li>
                <li>rilievi ambientali e rilievi con drone</li>
                <li>analisi multicriterio, gestione conflitti</li>
                <li>gestione dati ambientali</li>
                <li>supporto per finanziamenti</li>
                <li>formazione e divulgazione</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="px-md-0 px-1 pt-md-2 pt-3">
              <h4>Strumenti</h4>
              <ul className="px-4 py-2">
                <li>GIS</li>
                <li>modellistica</li>
                <li>software specialistici</li>
                <li>software open source</li>
                <li>drone e drone a infrarossi</li>
                <li>laser scanner e gps topo-grafico</li>
                <li>sonde e mulinelli per misure acque</li>
                <li>attrezzatura fotografica professionale</li>
                <li>strumentazione rilievi territoriali-ambientali</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="px-md-0 px-1 pt-md-2 pt-3">
              <h4>I nostri clienti</h4>
              <ul className="px-4 pt-2">
                <li>ministeri, enti statali</li>
                <li>regioni, province</li>
                <li>comuni e unioni</li>
                <li>enti parco e aree protette</li>
                <li>consorzi di bonifica e di settore</li>
                <li>aziende servizi e multiutilities</li>
                <li>agenzie di formazione, scuole e università</li>
                <li>associazioni ed enti morali</li>
                <li>editori, media</li>
              </ul>
            </div>
            <div className="px-md-0 px-1">
              <a href="/doc/IRIS_ElencoCommittenti_2020.pdf" target="_blank" rel="noopener">
                {" "}
                <FontAwesomeIcon icon={faArrowAltCircleDown} className="mr-1" />
                Elenco clienti
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row pt-4">
          <div className="col-md-12">
            <div className="px-md-0 px-1">
              <img
                className="img-fluid rounded panoramic mare-isola"
                src={require("../img/home/mare-isola.jpg").default}
                alt="Mare isola"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
