import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink, withRouter } from "react-router-dom";
import logo from "../img/logo/logo_iris.png";
import "../styles/Navbar.css";

const Navbar = ({ location }) => {
  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll);
  });
  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      navbar = document.querySelector(".navbar"),
      navbarToggler = document.querySelector(".navbar-toggler-icon"),
      logo = document.querySelector(".logo"),
      dropdownItem = document.querySelector(".dropdown-item");
    if (distanceY > shrinkOn) {
      navbar.classList.add("shrink");
      navbarToggler.classList.add("toggler-shrink");
      navbarToggler.classList.remove("resize-toggler-shrink");
      logo.classList.remove("resize");
      dropdownItem.classList.add("item-shrink");
    } else {
      navbar.classList.remove("shrink");
      navbarToggler.classList.add("resize-toggler-shrink");
      logo.classList.add("resize");
      dropdownItem.classList.remove("item-shrink");
    }
  };
  const handleCollapse = () => {
    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
    <div className="Navbar">
      <Helmet>
        <meta name="description" content="Iris Ambiente Sas - Strategie per l'Ambiente - Navigation Bar" />
      </Helmet>
      <nav className="Navbar navbar fixed-top navbar-expand-lg navbar-light bg-white">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="logo" alt="Iris logo" />
          </Link>
          <button
            className="navbar-toggler not-resize justify-content-center"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            id="navbarBtn"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <NavLink className="nav-link" to="/" activeClassName="nav-link-active" onClick={handleCollapse} exact>
                  Home<span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  className={`${location.pathname.startsWith("/chi-siamo") && "nav-link-active"} nav-link`}
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  Chi Siamo
                  <FontAwesomeIcon icon={faAngleDown} className="arrow" />
                </a>
                <div className="dropdown-menu">
                  <Link className="text-lg-left dropdown-item item-shrink" to="/chi-siamo/la-societa" onClick={handleCollapse}>
                    La società
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/chi-siamo/il-team" onClick={handleCollapse}>
                    Il team
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/chi-siamo/partner" onClick={handleCollapse}>
                    Partner
                  </Link>
                </div>
              </li>
              {/* </NavLink> */}
              <li className="nav-item dropdown">
                <a
                  className={`${location.pathname.startsWith("/servizi") && "nav-link-active"} nav-link`}
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  Servizi
                  <FontAwesomeIcon icon={faAngleDown} className="arrow" />
                </a>
                <div className="dropdown-menu">
                  <Link className="text-lg-left dropdown-item item-shrink" to="/servizi/progetti" onClick={handleCollapse}>
                    Progetti
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/servizi/studi" onClick={handleCollapse}>
                    Studi
                  </Link>
                  <Link
                    className="text-lg-left dropdown-item item-shrink"
                    to="/servizi/rilievi-geo-drone"
                    onClick={handleCollapse}
                  >
                    Rilievi geo-drone
                  </Link>
                  <Link
                    className="text-lg-left dropdown-item item-shrink"
                    to="/servizi/rilievi-idro-ambientali"
                    onClick={handleCollapse}
                  >
                    Rilievi idro-ambientali
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/servizi/formazione" onClick={handleCollapse}>
                    Formazione
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`${location.pathname.startsWith("/esperienze") && "nav-link-active"} nav-link`}
                  href="#"
                  id="esperienze-click"
                  role="button"
                  data-toggle="dropdown"
                >
                  Esperienze
                  <FontAwesomeIcon icon={faAngleDown} className="arrow" />
                </a>
                <div className="dropdown-menu">
                  <Link
                    className="text-lg-left dropdown-item item-shrink"
                    to="/esperienze/acque-e-suolo"
                    onClick={handleCollapse}
                  >
                    Acque e suolo
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/esperienze/territorio" onClick={handleCollapse}>
                    Territorio
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/esperienze/ecoturismo" onClick={handleCollapse}>
                    Ecoturismo
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/esperienze/energia" onClick={handleCollapse}>
                    Energia
                  </Link>
                  <Link className="text-lg-left dropdown-item item-shrink" to="/esperienze/formazione" onClick={handleCollapse}>
                    Formazione
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contatti"
                  activeClassName="nav-link-active"
                  className="nav-link mb-2 mb-md-0"
                  onClick={handleCollapse}
                  exact
                >
                  Contatti
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Navbar);
