import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "../styles/Various.css";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

mapboxgl.workerClass = MapboxWorker;
const Contatti = () => {
  const mapContainer = useRef();
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [11.133121326865512, 43.68784538281673],
      zoom: 11,
    });
    new mapboxgl.Marker().setLngLat([11.133123076174229, 43.68758775632642]).addTo(map);
    map.addControl(new mapboxgl.NavigationControl());
    document.title = "Contatti";
    return () => map.remove();
  }, []);
  return (
    <div className="Contatti">
      <Helmet>
        <meta
          name="description"
          content="Contatta iris strategia per l'ambiente sas, puoi inviarci una mail o telefonarci, o raggiungerci di persona nella nostra sede su appuntamento"
        />
        <link href="https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css" rel="stylesheet" />
      </Helmet>
      <div className="row">
        <div className="col-md-6">
          <h1>Contatti</h1>
          <h3 className="pt-4">IRIS Strategie per l’Ambiente sas</h3>
          <div className="pt-3">
            Maurizio Bacci
            <br />
            Via Volterrana 179, 50026 Cerbaia (FI)
            <br />
            e-mail:{" "}
            <a href="mailto:iris@irisambiente.it" target="_blank" rel="noopener">
              iris@irisambiente.it
            </a>
            <br />
            PEC:{" "}
            <a href="mailto:irisambiente@pcert.postecert.it" target="_blank" rel="noopener">
              irisambiente@pcert.postecert.it
            </a>
            <br />
            <span>mobile: +39 335 71 23 458</span>
            <br />
            <div className="mt-1">
              <a
                href="https://www.facebook.com/pages/category/Local-Business/IRIS-Ambiente-380072679828/"
                target="_blank"
                rel="noopener"
              >
                <FontAwesomeIcon icon={faFacebook} className="social mr-2" />
              </a>
              <a href="https://www.linkedin.com/company/iris-ambiente-sas/about/" target="_blank" rel="noopener">
                <FontAwesomeIcon icon={faLinkedin} className="social" />
              </a>
            </div>
          </div>
          <div className="my-4">
            <hr />
          </div>
          <p>
            <strong>Per raggiungerci con i mezzi pubblici</strong>
          </p>
          <p>
            Da Firenze, vicino alla stazione FS di Santa Maria Novella prendere l’autobus della SITA n. 37: linea Firenze –
            Montespertoli – Certaldo e scendere alla prima fermata di Cerbaia, Cerbaia di Sopra (dopo circa 40 minuti).
          </p>
          <a href="https://www.piubus.it/" target="_blank" rel="noopener">
            Orario autobus <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
        <div className="col-md-6 pt-md-0 pt-4">
          <div className="map-container rounded" ref={mapContainer} />
        </div>
      </div>
    </div>
  );
};

export default Contatti;
