import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LaSocieta from "./components/chi-siamo/LaSocieta";
import Partner from "./components/chi-siamo/Partner";
import Team from "./components/chi-siamo/Team";
import Contatti from "./components/Contatti";
import Esperienze from "./components/esperienze/Esperienze";
import SubEsperienze from "./components/esperienze/SubEsperienze";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import Progetti from "./components/servizi/Progetti";
import RilieviGeoDrone from "./components/servizi/RilieviGeoDrone";
import RilieviIdro from "./components/servizi/RilieviIdro";
import ServiziFormazione from "./components/servizi/ServiziFormazione";
import Studi from "./components/servizi/Studi";
import seedEsperienze from "./data/seedEsperienze";
import seedSubEsperienze from "./data/seedSubEsperienze";

const Routes = (props) => {
  const getEsp = ({ match }) => {
    let id = match.params.espId;
    let currentEsp = seedEsperienze.find((esp) => esp.id.toLowerCase() === id.toLowerCase());
    if (currentEsp) return <Esperienze {...props} esp={currentEsp} />;
    else return <Redirect to="/404" />;
  };
  const getSubEsp = ({ match }) => {
    let id = match.params.subEspId;
    let currentSubEsp = seedSubEsperienze.find((subEsp) => subEsp.id.toLowerCase() === id.toLowerCase());
    if (currentSubEsp) return <SubEsperienze {...props} subEsp={currentSubEsp} />;
    else return <Redirect to="/404" />;
  };
  return (
    <Switch>
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/chi-siamo/la-societa" render={() => <LaSocieta />} />
      <Route exact path="/chi-siamo/il-team" render={() => <Team />} />
      <Route exact path="/chi-siamo/partner" render={() => <Partner />} />
      <Route exact path="/servizi/progetti" render={() => <Progetti />} />
      <Route exact path="/servizi/studi" render={() => <Studi />} />
      <Route exact path="/servizi/rilievi-geo-drone" render={() => <RilieviGeoDrone />} />
      <Route exact path="/servizi/rilievi-idro-ambientali" render={() => <RilieviIdro />} />
      <Route exact path="/servizi/formazione" render={() => <ServiziFormazione />} />
      <Route exact path="/esperienze/:espId" render={getEsp} />
      <Route exact path="/esperienze/:espId/:subEspId" render={getSubEsp} />
      <Route exact path="/contatti" render={() => <Contatti />} />
      <Route render={() => <NotFound />} status={404} />
    </Switch>
  );
};

export default Routes;
