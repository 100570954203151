import parse from "html-react-parser";
import React from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_HOVER_CDN } from "../../utils/constants";
import "../../styles/Various.css";

const SubEspImg = ({ path1, path2, description, location, link }) => {
  return (
    <div className="SubEspImg col-lg-6 pr-lg-4">
      <Helmet>{IMAGE_HOVER_CDN}</Helmet>
      <figure className="figure">
        <figure className="imghvr-slide-left rounded">
          <img
            src={require(`../../img/esperienze/${path1}`).default}
            alt={description}
            className={`subcategories-img figure-img img-fluid ${path1.includes("pubblicazioni") && "pubblicazioni-img"}`}
          />

          {path2 && (
            <figcaption>
              <img
                src={require(`../../img/esperienze/${path2}`).default}
                alt={description}
                className="subcategories-img figure-img img-fluid"
              />
            </figcaption>
          )}
        </figure>
        <div className="card bg-light border-light">
          <figcaption className="figcaption-subcategories py-2 text-center card-body">
            {parse(description)}
            {link && (
              <a href={link} target="_blank" rel="noopener">
                : per approfondire
              </a>
            )}
            <br />
            {parse(location)}
          </figcaption>
        </div>
      </figure>
    </div>
  );
};

export default SubEspImg;
