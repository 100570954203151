import { faChevronLeft, faChevronRight, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import seedSubEsperienze from "../../data/seedSubEsperienze";
import "../../styles/Various.css";
import SubEspImg from "./SubEspImg";

const SubEsperienze = ({ subEsp }) => {
  useEffect(() => {
    document.title = subEsp.title;
  });
  const findNext = (id) => {
    let i = seedSubEsperienze.indexOf(id);
    i++;
    i = i % seedSubEsperienze.length;
    return seedSubEsperienze[i];
  };
  const findPrev = (id) => {
    let i = seedSubEsperienze.indexOf(id);
    if (i === 0) i = seedSubEsperienze.length;
    i--;
    return seedSubEsperienze[i];
  };
  let next = findNext(subEsp);
  let prev = findPrev(subEsp);
  const subEspImgs = subEsp.imgs.map((img) => (
    <SubEspImg
      path1={img.path1}
      path2={img.path2}
      description={img.description}
      location={img.location}
      link={img.link}
      key={img.path1.split("/").pop().slice(0, -4)}
    />
  ));
  let category = subEsp.category.toLowerCase().replace(/ /g, "-");
  return (
    <div className="SubEsp">
      <title>{subEsp.title}</title>
      <section className="SubEsp-title">
        <h1 className="pb-5 d-flex flex-wrap">
          <Link to={`/esperienze/${category}`}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
            {subEsp.category}
          </Link>
          /{subEsp.title}
        </h1>
      </section>
      <section className="SubEsp-img">
        <div className="row">{subEspImgs}</div>
      </section>
      <section className="SubEsp-change-page py-4">
        <Link className="float-left btn btn-light" to={`/esperienze/${category}/${prev.id}`}>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
          <span className="d-none d-sm-inline-block">{prev.title}</span>
        </Link>
        <Link className="float-right btn btn-light" to={`/esperienze/${category}/${next.id}`}>
          <span className="d-none d-sm-inline-block">{next.title}</span>
          <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
        </Link>
      </section>
    </div>
  );
};

export default SubEsperienze;
