import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Partner = () => {
  useEffect(() => {
    document.title = "Partner";
  });
  return (
    <div className="Partner">
      <Helmet>
        <meta
          name="description"
          content=" Per far fronte alle esigenze di multidisciplinarietà e conoscenza ricorriamo spesso alla collaborazione o
                  associazione con società, studi professionali e tecnici con cui operiamo da anni. In questo modo copriamo tutte le competenze e miglioriamo la capacità di intervento anche in territori lontani
                  dalla nostra sede."
        />
      </Helmet>
      <section>
        <div className="row align-items-center">
          <div className="col-md-7 order-2 order-md-1">
            <h1 className="pb-4 pt-5 pt-md-0">Partner</h1>
            <div className="pr-md-3 pr-0">
              <p>
                Per far fronte alle esigenze di multidisciplinarietà e conoscenza ricorriamo spesso alla collaborazione o
                associazione con società, studi professionali e tecnici con cui operiamo da anni. <br />
                In questo modo copriamo tutte le competenze e miglioriamo la capacità di intervento anche in territori lontani
                dalla nostra sede.
              </p>
              <p>Segue un elenco dei partner con cui abbiamo collaborato negli anni.</p>
            </div>
          </div>
          <div className="col-md-5  order-1 order-md-2">
            <img className="img-fluid rounded" src={require("../../img/chi-siamo/partner/partner.jpg").default} alt="Partner" />
          </div>
        </div>
      </section>
      <section>
        <div className="pt-md-5 pt-4 table-responsive">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>DENOMINAZIONE</th>
                <th>SEDE</th>
                <th>COMPETENZA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ABACO AMBIENTE srl</td>
                <td>Empoli</td>
                <td>analisi chimiche</td>
              </tr>
              <tr>
                <td>ALCEDO AMBIENTE</td>
                <td>Montevarchi</td>
                <td>educazione ambientale</td>
              </tr>
              <tr>
                <td>AMBIENTE ITALIA srl</td>
                <td>Milano</td>
                <td>Pianificazione territoriale e gestione ambientale</td>
              </tr>
              <tr>
                <td>Arch. Enrico Guaitoli Panini</td>
                <td>Modena</td>
                <td>parchi, percorsi mobilità dolce</td>
              </tr>
              <tr>
                <td>Arch. Luigi Minozzi</td>
                <td>Siracusa</td>
                <td>urbanistica e territorio</td>
              </tr>
              <tr>
                <td>Arch. Vittorio Fiore</td>
                <td>Asti</td>
                <td>architettura, urbanistica, territorio</td>
              </tr>
              <tr>
                <td>ARCHINES studio associato</td>
                <td>Firenze</td>
                <td>energia, bioedilizia</td>
              </tr>
              <tr>
                <td>ATELIERPROJECT srl</td>
                <td>Aosta</td>
                <td>ingegneria ambientale, idraulica, territorio</td>
              </tr>
              <tr>
                <td>BIOTEC S.A.</td>
                <td>Svizzera e Francia</td>
                <td>ingegneria naturalistica e biologia ambientale</td>
              </tr>
              <tr>
                <td>BORGHI AZIO SpA</td>
                <td>San Polo D'Enza</td>
                <td>materiali difesa suolo</td>
              </tr>
              <tr>
                <td>BOSCAGLIA coop</td>
                <td>Radicondoli</td>
                <td>lavori forestali</td>
              </tr>
              <tr>
                <td>Centro Studi Naturalistici</td>
                <td>Puglia</td>
                <td>scienze naturali, ingegneria ambientale e territorio</td>
              </tr>
              <tr>
                <td>COGECSTRE</td>
                <td>Penne (PE)</td>
                <td>casa editrice natura</td>
              </tr>
              <tr>
                <td>CRIT srl</td>
                <td>Firenze</td>
                <td>energetica</td>
              </tr>
              <tr>
                <td>Dott. Alessandro Forci</td>
                <td>Sardegna</td>
                <td>geologia, territorio</td>
              </tr>
              <tr>
                <td>Dott. Gianni Forte</td>
                <td>Piemonte</td>
                <td>scienze agrarie, territorio</td>
              </tr>
              <tr>
                <td>Dott. Mauro Casti</td>
                <td>Cagliari</td>
                <td>scienze naturali</td>
              </tr>
              <tr>
                <td>Dott. Rocco Lafratta</td>
                <td>Napoli</td>
                <td>geologia ambientale, territorio</td>
              </tr>
              <tr>
                <td>DREAM scrl</td>
                <td>Pistoia e Pratovecchio</td>
                <td>forestale, ambiente e territorio</td>
              </tr>
              <tr>
                <td>ECO &amp; ECO srl</td>
                <td>Bologna</td>
                <td>economia e scienze sociali</td>
              </tr>
              <tr>
                <td>ECOFORM, arch. Rainer Winter</td>
                <td>Castelfiorentino</td>
                <td>bioarchitettura</td>
              </tr>
              <tr>
                <td>ECOINGEGNO, ing. Giancarlo Gusmaroli</td>
                <td>Venezia</td>
                <td>ingegneria ambientale, edilizia, territorio</td>
              </tr>
              <tr>
                <td>ECORE srl</td>
                <td>Firenze</td>
                <td>ingegneria ambientale, idraulica, energetica, sicurezza</td>
              </tr>
              <tr>
                <td>ELICONA srl</td>
                <td>Scandicci</td>
                <td>materiali bioedilizia</td>
              </tr>
              <tr>
                <td>ESA ENGINEERING</td>
                <td>Milano, Firenze</td>
                <td>ingegneria</td>
              </tr>
              <tr>
                <td>ESCO ITALIA srl</td>
                <td>Siena</td>
                <td>efficenza energetica e finanza finalizzata</td>
              </tr>
              <tr>
                <td>FIRENZE RAFTING</td>
                <td>Firenze</td>
                <td>Sport e natura</td>
              </tr>
              <tr>
                <td>GEAPROGETTI sas</td>
                <td>Ravenna</td>
                <td>piste ciclabili, territorio</td>
              </tr>
              <tr>
                <td>HYLA studio associato</td>
                <td>Tuoro (Umbria)</td>
                <td>scienze naturali</td>
              </tr>
              <tr>
                <td>ILEX sas</td>
                <td>Fontecchio (Abruzzo)</td>
                <td>Educazione, paesaggistica, sviluppo rurale, turismo sostenibile</td>
              </tr>
              <tr>
                <td>Ing. Matteo Ciocchetta</td>
                <td>Milano</td>
                <td>ingegneria energetica</td>
              </tr>
              <tr>
                <td>Ing. Roberto Marconi</td>
                <td>Ancona</td>
                <td>ingegneria industriale, energia</td>
              </tr>
              <tr>
                <td>Ingg. Simona Trecarichi e Danilo Colomela</td>
                <td>Palermo</td>
                <td>ingegneria ambientale, bioedilizia, territorio, formazione</td>
              </tr>
              <tr>
                <td>IRIDRA srl</td>
                <td>Firenze</td>
                <td>fitodepurazione</td>
              </tr>
              <tr>
                <td>ISTAL NUOVA srl</td>
                <td>Tavarnelle (FI)</td>
                <td>potabilizzazione acque</td>
              </tr>
              <tr>
                <td>NEMO srl</td>
                <td>Firenze</td>
                <td>scienze naturali</td>
              </tr>
              <tr>
                <td>PIUARCH srl</td>
                <td>Milano</td>
                <td>architettura</td>
              </tr>
              <tr>
                <td>PROJECTO srl</td>
                <td>Firenze</td>
                <td>bioarchitettura</td>
              </tr>
              <tr>
                <td>SERVIZI TERRITORIO scrl</td>
                <td>Milano</td>
                <td>ingegneria energetica</td>
              </tr>
              <tr>
                <td>SIMURG srl</td>
                <td>Livorno</td>
                <td>ricerche socio-economiche</td>
              </tr>
              <tr>
                <td>STINGEA srl</td>
                <td>Firenze</td>
                <td>bonifiche, analisi inquinamento</td>
              </tr>
              <tr>
                <td>STUDIO PERAZZI</td>
                <td>Milano</td>
                <td>paesaggistica</td>
              </tr>
              <tr>
                <td>TECNOLOGIE URBANE srl</td>
                <td>Firenze</td>
                <td>mobilità sostenibile, eco-tecnologie, industrial design, architettura</td>
              </tr>
              <tr>
                <td>URBAN TEAM di Giorgio Ramaroli</td>
                <td>Milano</td>
                <td>architettura, urbanistica</td>
              </tr>
              <tr>
                <td>VIVAI ZELARI EUROAMBIENTE</td>
                <td>Pistoia</td>
                <td>paesaggio, vivaistica</td>
              </tr>
              <tr>
                <td>VPE srl</td>
                <td>Roma</td>
                <td>certificazione progetti</td>
              </tr>
              <tr>
                <td>Webmap srl</td>
                <td>Pisa</td>
                <td>Piattaforme web e app per sentieristica</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section>
        <img src={require("../../img/partner/MAUB0889b.jpg").default} alt="" className="img-fluid rounded mt-5" />
      </section>
    </div>
  );
};

export default Partner;
