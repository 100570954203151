import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { IMAGE_HOVER_CDN } from "../../utils/constants";

const EspImg = ({ name, link, path }) => {
  useEffect(() => {
    document.title = name;
  });

  return (
    <div key={name} className="EspImg col-md-4 pr-md-4 pt-3">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta name="description" content="Singole categorie dei lavori svolti" />
      </Helmet>
      <figure className="figure">
        <img
          src={require(`../../img/esperienze/${path}`).default}
          alt={name}
          className="figure-img img-fluid rounded esperienze-img"
        />
        <figcaption className="figcaption-esperienze pt-2">
          <Link className="btn btn-light stretched-link" to={link}>
            {name}
          </Link>
        </figcaption>
      </figure>
    </div>
  );
};

export default EspImg;
