import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_HOVER_CDN } from "../../utils/constants";

const Progetti = () => {
  useEffect(() => {
    document.title = "Progetti";
  });
  return (
    <div className="Progetti">
      <Helmet>
        {IMAGE_HOVER_CDN}
        <meta
          name="description"
          content="sostenibilità ambientale, sostenibilità sociale, analisi multicriterio, partecipazione e concertazione"
        />
      </Helmet>
      <h1 className="pb-4">Progetti</h1>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h4>Principi generali</h4>
            <ul className="px-4 py-2">
              <li>sostenibilità ambientale</li>
              <li>sostenibilità sociale</li>
              <li>analisi multicriterio</li>
              <li>interdisciplinarietà</li>
              <li>partecipazione/concertazione</li>
              <li>controllo qualità processi di lavoro</li>
              <li>utilizzo software open source</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/progetti/1-BacinoMagra.jpg").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/progetti/2-ucm-consegna.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        {" "}
        <div className="row flex-md-row-reverse pt-5 pb-5 align-items-center">
          <div className="col-md-5 offset-md-1">
            <h4>Scelta interventi e materiali</h4>
            <ul className="px-4 py-2">
              <li>biodegradabilità</li>
              <li>risorse rinnovabili</li>
              <li>filiera corta e tracciata</li>
              <li>elevata durata</li>
              <li>massimo rapporto efficacia&amp;qualità/costo</li>
              <li>bassi oneri di manutenzione</li>
              <li>mano d'opera locale</li>
              <li>sicurezza</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/progetti/3-corbara_MBSU8940.JPG").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/progetti/4-Parmigniana_sezioneFossetta.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row align-items-center">
          <div className="col-md-6 pb-4 pb-md-0">
            <h4>Metodologia</h4>
            <ul className="px-4 py-2">
              <li>analisi e interpretazione contesto territoriale e sociale</li>
              <li>concertazione con Enti e soggetti interessati</li>
              <li>
                utilizzo strumentazione professionale: vedi <a href="/servizi/rilievi">rilievi</a>{" "}
              </li>
              <li>
                utilizzo di software <em>opensource</em>
              </li>
              <li>implementazione dati e cartografie su QGIS</li>
              <li>banca dati particolari costruttivi e analisi prezzi</li>
              <li>comunicazione e animazione</li>
              <li>direzione lavori vigile e collaudo rigoroso</li>
            </ul>
            <a href="/doc/metodologiaIRIS.pdf" target="_blank" rel="noopener">
              {" "}
              <i className="far fa-arrow-alt-circle-down mr-1" />
              Metodologia IRIS
            </a>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/progetti/5-MAUB0931.JPG").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-right border"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/progetti/6-Capraia2.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-right"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section>
        <div className="row flex-md-row-reverse pt-5 align-items-center">
          <div className="col-md-5 offset-md-1">
            <h4>Attività collaterali</h4>
            <ul className="px-4 py-2">
              <li>Supporto procedure autorizzative</li>
              <li>Presentazioni pubbliche ed eventi</li>
              <li>Brochure e pannelli informativi</li>
              <li>Assistenza alla manutenzione</li>
              <li>Monitoraggio</li>
            </ul>
          </div>
          <div className="col-md-6">
            <figure className="imghvr-slide-left rounded">
              <img
                src={require("../../img/servizi/progetti/7-Querciola3.JPG").default}
                alt=""
                className="img-fluid rilievi-img rounded float-lg-left"
              />
              <figcaption>
                <img
                  src={require("../../img/servizi/progetti/8-Capraia4.jpg").default}
                  alt=""
                  className="img-fluid rilievi-img rounded float-lg-left"
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Progetti;
